import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import FetchModule from "../../Share/Network/FetchModule";

import XLSX from 'sheetjs-style';

export default function MemberListExportModal(props) {
  const { onClickExit } = props;

  const [isClickedValidMember, setIsClickedValidMember] = useState(false);
  const [isClickedExpiredMember, setIsClickedExpiredMember] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('유효회원 데이터 다운로드중...');
  const [width, setWidth] = useState(0);
  const [isEnd, setIsEnd] = useState(false);

  const ExitTextRef = useRef();

  // 화면 스크롤 제어 //
  useEffect(() => {
    // document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    // return () => {
    //   const scrollY = document.body.style.top;
    //   document.body.style.cssText = `position: ""; top: "";`
    //   window.scrollTo(0, parseInt(scrollY || '0') * -1)
    // }

    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 취소버튼 호버링 //
  function exitButtonOnMouseOver() {
    ExitTextRef.current.style.color = '#777777';
  }

  // 취소버튼 호버링 //
  function exitButtonOnMouseLeave() {
    ExitTextRef.current.style.color = '#CBCBCB';
  }

  // 다운로드 버튼 클릭시 //
  async function onClickDownloadButton() {
    if (!isClickedValidMember && !isClickedExpiredMember) return;

    await setIsLoading(true);

    const fetchModule = new FetchModule();
    (
    	async () => {
        var alive = [];
        var expired = [];

        if (isClickedValidMember) {
          await setLoadingText('유효회원 데이터 다운로드중...');

          const aliveResponseData = await fetchModule.postData('member/alive/', 'POST');
          
          if (aliveResponseData.status === 200) {
            // setAlive(aliveResponseData.data);
            alive = await aliveResponseData.data.slice();
            await setWidth(90);
          } 
        }

        if (isClickedExpiredMember) {
          await setLoadingText('만료회원 데이터 다운로드중...');

          const expiredResponseData = await fetchModule.postData('member/expired/', 'POST');
          
          if (expiredResponseData.status === 200) {
            // setExpired(expiredResponseData.data);
            expired = await expiredResponseData.data.slice();
            await setWidth(200);
          }
        }
        // console.log(alive);
        await setLoadingText('회원 데이터 포맷 변환중 ...');
        await setWidth(274);
        exportToExcel(alive, expired);
    	}
    )();
  }

  // XLSX 추출 //
  async function exportToExcel(alive, expired) {
    // var mergeData = await alive.concat(expired);
    for (var i = 0; i < alive.length; i++) {
      alive[i].division = '유효회원';
    }

    for ( i = 0; i < expired.length; i++) {
      expired[i].division = '만료회원';
    }
    
    var mergeData = await alive.concat(expired);

    var finalData = [];

    for (i = 0; i < mergeData.length; i++) {
      var userId = '';

      // Link Coach 연동이 되어있는경우 //
      if (!mergeData[i].linkCoachId.includes('None-')) {
        var divideId = mergeData[i].linkCoachId.split('.');

        for (var j = 1; j < divideId.length; j++) {
          if (j === divideId.length - 1) userId += divideId[j];
          else userId += divideId[j] + '.';
        }
        
        if (divideId[0] !== 'kakao'
          && divideId[0] !== 'google'
          && divideId[0] !== 'apple'
          && divideId[0] !== 'naver') {
            userId = divideId[0] + '.' + userId;
          }
      }

      var phone = mergeData[i].phone === '' ? 'None' : (mergeData[i].phone.slice(0,3) + '-' + mergeData[i].phone.slice(3, 7) + '-' + mergeData[i].phone.slice(7, 11));
      var startDate = mergeData[i].startDate.split('T')[0];
      var endDate = mergeData[i].endDate.split('T')[0];

      finalData.push({
        division: mergeData[i].division,
        name: mergeData[i].name,
        gender: mergeData[i].gender === 'male' ? '남성' : '여성',
        phone: phone,
        email: userId === '' ? 'None' : userId,
        memberShipName: mergeData[i].memberShipName,
        startDate: startDate,
        endDate: endDate
      })
    }
    
    const ws = await XLSX.utils.json_to_sheet(finalData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Member")
    XLSX.writeFile(wb, '회원명단.xlsx');

    await setLoadingText('데이터 추출 완료');
    await setWidth(313);
    await setIsEnd(true);
  }

  // XLSX추출 후 닫기 버튼 클릭시 //
  function onClickExitAfterExport() {
    if (isEnd) {
      onClickExit();
      // setIsEnd(false);
      // setIsLoading(false);
      // setWidth(0);
    }
    else {
      alert('아직 데이터 변환이 진행중입니다!\n잠시 후 다시시도해주세요!');
    }
  }

  return (
    <TopLevelWrapper>
      <Modal>
        <ModalInnerWrapper>
          <TitleWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>회원목록 다운로드</Text>
          </TitleWrapper>
          {
            !isLoading &&
            <ExplanationWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>다운로드를 진행할 회원 구분을 선택해주세요</Text>
            </ExplanationWrapper>
          }
          {
            isLoading ?
              <LoadingBarWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='pointer'>{loadingText}</Text>
                <LoadingBar>
                  <PersentBar width={width} />
                </LoadingBar>
              </LoadingBarWrapper>
              :
              <CheckBoxTotalWrapper>
                <CheckBoxWrapper onClick={() => setIsClickedValidMember(!isClickedValidMember)}>
                  <CheckBox isClicked={isClickedValidMember} />
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='pointer'>유효회원</Text>
                </CheckBoxWrapper>
                <CheckBoxWrapper onClick={() => setIsClickedExpiredMember(!isClickedExpiredMember)}>
                  <CheckBox isClicked={isClickedExpiredMember} />
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='pointer'>만료회원</Text>
                </CheckBoxWrapper>
              </CheckBoxTotalWrapper>
          }
          {
            isLoading ?
              <ButtonWrapper isLoading={isLoading}>
                <ExitButton onMouseOver={exitButtonOnMouseOver} onMouseLeave={exitButtonOnMouseLeave} onClick={onClickExitAfterExport}>
                  <ExitText ref={ExitTextRef}>닫기</ExitText>
                </ExitButton>
              </ButtonWrapper>
              :
              <ButtonWrapper isLoading={isLoading}>
                <ExitButton onMouseOver={exitButtonOnMouseOver} onMouseLeave={exitButtonOnMouseLeave} onClick={onClickExit}>
                  <ExitText ref={ExitTextRef}>취소</ExitText>
                </ExitButton>
                <DownloadButton isValid={isClickedValidMember || isClickedExpiredMember} onClick={onClickDownloadButton}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>다운로드</Text>
                </DownloadButton>
              </ButtonWrapper>
          }
        </ModalInnerWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);
  
  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 371px;
  height: 248px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 84.9%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;
`;

const CheckBoxTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 16px;

  gap: 12px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 15px;
  height: 15px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;
`;

const LoadingBarWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 42px;

  gap: 8px;
`;

const LoadingBar = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 313px;
  height: 18px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;
  background-color: #F2F2F2;
`;

const PersentBar = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: 20px;

  border-radius: 4px;
  background-color: #6DD49E;

  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: ${props => props.isLoading ? `42px` : `35px`};

  gap: 16px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover { 
    border: 1px solid #777777;
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ExitText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #CBCBCB;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;

const DownloadButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: ${props => props.isValid ? `#5BD98A` : `#ECECEC`};

  :hover {
    cursor: pointer;
    background-color: ${props => props.isValid ? `#2BCB67` : `#ECECEC`};
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;