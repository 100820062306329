import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ResultExerciseBox from './RenewalResultExerciseBox';
import ExerciseResultBox from './RenewalExerciseResultBoxInRankingDetail';

export default function RenewalResultSectionBoxInRankingDetail(props) {
  const {
    sectionData,
    index,
    userName,
  } = props

  const [title, setTitle] = useState('');
  const [scoreKeyword, setScoreKeyword] = useState('점수 없음');
  const [scorePriority, setScorePriority] = useState('');

  const [isClickProgramMore, setIsClickProgramMore] = useState(false);
  const [isClickNoteMore, setIsClickNoteMore] = useState(false);
  const [dataReps, setDataReps] = useState('');

  // Section 이름 만들기 //
  useEffect(() => {
    let newTitle = '';

    if (sectionData.tag === 'ForTime') {
      if (sectionData.round === 1) newTitle = 'For Time';
      else newTitle = sectionData.round + ' Rounds For Time';
    }
    else if (sectionData.tag === 'AMRAP') {
      let minute = parseInt(sectionData.exerciseTime / 60);
      let second = parseInt(sectionData.exerciseTime % 60);

      if (minute > 0) {
        if (second > 0) newTitle += minute + ' Minute ' + second + ' Second AMRAP';
        else newTitle += minute + ' Minute AMRAP';
      }
      else {
        if (second > 0) newTitle += second + ' Second AMRAP';
        else newTitle += 'AMRAP';
      }
    }
    else if (sectionData.tag === 'EMOM') {
      let minute = parseInt((sectionData.exerciseTime * sectionData.round) / 60);
      let second = parseInt((sectionData.exerciseTime * sectionData.round) % 60);

      if (minute > 0) {
        if (second > 0) newTitle += minute + ' Minute ' + second + ' Second EMOM';
        else newTitle += minute + ' Minute EMOM';
      }
      else {
        if (second > 0) newTitle += second + ' Second EMOM';
        else newTitle += 'EMOM';
      }
    }
    else if (sectionData.tag === 'TABATA') {
      let onMin = parseInt(sectionData.exerciseTime / 60);
      let onSec = parseInt(sectionData.exerciseTime % 60);
      let offMin = parseInt(sectionData.restTime / 60);
      let offSec = parseInt(sectionData.restTime % 60);

      newTitle = 'On: ' + onMin + ':' + numberPad(onSec, 2) + ' / Off: ' + offMin + ':' + numberPad(offSec, 2) + ' ' + sectionData.round + 'R TABATA';
    }
    else if (sectionData.tag === 'SINGLE') {
      if (sectionData.exerciseList.length === 1) newTitle += sectionData.exerciseList[0].movement.movementName;
      else newTitle += 'Multiple Single';
    }
    else if (sectionData.tag === 'Custom') {
      
      if (sectionData.customTitle !== '') newTitle = sectionData.customTitle;
      // else {
      //   if (sectionData.scoreKeyword === 'Not Scored') sectionData.scoreKeyword = '점수 없음';
      //   else if (sectionData.scoreKeyword === 'Time') sectionData.scoreKeyword = '시간';
      //   else if (sectionData.scoreKeyword === 'Reps') sectionData.scoreKeyword = '렙수';
      //   else if (sectionData.scoreKeyword === 'Rounds And Reps') sectionData.scoreKeyword = '라운드 + 렙수';
      //   else if (sectionData.scoreKeyword === 'Distance') sectionData.scoreKeyword = '거리';
      //   else if (sectionData.scoreKeyword === 'Weight') sectionData.scoreKeyword = '무게';
      //   else if (sectionData.scoreKeyword === 'Points') sectionData.scoreKeyword = '포인트';
      //   else if (sectionData.scoreKeyword === 'Peak Watts') sectionData.scoreKeyword = '피크와트';
      //   else if (sectionData.scoreKeyword === 'Max Speed') sectionData.scoreKeyword = '최고 속도';

      //   if (sectionData.scorePriority=== 'heigher') sectionData.scorePriority = '높을 수록';
      //   else if (sectionData.scorePriority === 'lower') sectionData.scorePriority = '낮을 수록';
      // }
      else {
        if (sectionData.scoreKeyword === 'Not Scored') sectionData.scoreKeyword = '점수 없음';
        else if (sectionData.scoreKeyword === 'Time') sectionData.scoreKeyword = '시간';
        else if (sectionData.scoreKeyword === 'Reps') sectionData.scoreKeyword = '렙수';
        else if (sectionData.scoreKeyword === 'Rounds And Reps') sectionData.scoreKeyword = '라운드 + 렙수';
        else if (sectionData.scoreKeyword === 'Distance') sectionData.scoreKeyword = '거리';
        else if (sectionData.scoreKeyword === 'Weight') sectionData.scoreKeyword = '무게';
        else if (sectionData.scoreKeyword === 'Points') sectionData.scoreKeyword = '포인트';
        else if (sectionData.scoreKeyword === 'Peak Watts') sectionData.scoreKeyword = '피크와트';
        else if (sectionData.scoreKeyword === 'Max Speed') sectionData.scoreKeyword = '최고 속도';

        if (sectionData.scorePriority=== 'heigher') sectionData.scorePriority = '높을 수록';
        else if (sectionData.scorePriority === 'lower') sectionData.scorePriority = '낮을 수록';

        // let customText = sectionData.scoreKeyword + (sectionData.scoreKeyword === 'Not Scored' ? '' : ' ' + sectionData.scorePriority);
        newTitle += 'Custom : ' + ((sectionData.scoreKeyword !== '점수 없음' 
                                  && sectionData.scoreKeyword !== '피크와트'
                                  && sectionData.scoreKeyword !== '최고 속도') ? (sectionData.scoreKeyword + ' ' + sectionData.scorePriority) : sectionData.scoreKeyword);
        
        setScoreKeyword(sectionData.scoreKeyword);
        setScorePriority(sectionData.scorePriority);
        // // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
        // if (sectionData.scoreKeyword === 'Not Scored') setScoreKeyword('점수 없음');
        // else if (sectionData.scoreKeyword === 'Time') setScoreKeyword('시간');
        // else if (sectionData.scoreKeyword === 'Reps') setScoreKeyword('렙수');
        // else if (sectionData.scoreKeyword === 'Rounds And Reps') setScoreKeyword('라운드 + 렙수');
        // else if (sectionData.scoreKeyword === 'Distance') setScoreKeyword('거리');
        // else if (sectionData.scoreKeyword === 'Weight') setScoreKeyword('무게');
        // else if (sectionData.scoreKeyword === 'Points') setScoreKeyword('포인트');
        // else if (sectionData.scoreKeyword === 'Peak Watts') setScoreKeyword('피크와트');
        // else if (sectionData.scoreKeyword === 'Max Speed') setScoreKeyword('최고 속도');

        // if (sectionData.scorePriority === 'heigher') setScorePriority('높을 수록');
        // else if (sectionData.scorePriority === 'lower') setScorePriority('낮을 수록');
      }
    }
    
    setTitle(newTitle);
  }, [sectionData])

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // 섹션 순서 텍스트 결정 //
  function decideSectionText() {
    if (sectionData.isScale) return String.fromCharCode(index + 65) + '-' + (sectionData.scaleSectionIndex + 1);
    else return String.fromCharCode(index + 65)
  }

  // For Time Timecap 계산 //
  function decideTimeCap() {
    let min = parseInt(sectionData.timeLimit / 60);
    let sec = parseInt(sectionData.timeLimit % 60);

    if (min > 0 && sec > 0) return 'Timecap ' + min + 'min ' + sec + 'sec';
    else if (min > 0) return 'Timecap ' + min + 'min';
    else if (sec > 0) return 'Timecap ' + sec + 'sec';
    else return 'Timecap 0sec'; 
  }

  // TABATA ON OFF 계산 //
  function decideTABATAOnOff(division) {
    if (division === 'ON') {
      let min = parseInt(sectionData.exerciseTime / 60);
      let sec = parseInt(sectionData.exerciseTime % 60);
  
      if (min > 0 && sec > 0) return min + ':' + numberPad(sec, 2);
      else if (min > 0) return min + ':00'
      else if (sec > 0) return '0:' + numberPad(sec, 2);
      else return '0:00'; 
    }
    else {
      let min = parseInt(sectionData.restTime / 60);
      let sec = parseInt(sectionData.restTime % 60);
  
      if (min > 0 && sec > 0) return min + ':' + numberPad(sec, 2);
      else if (min > 0) return min + ':00'
      else if (sec > 0) return '0:' + numberPad(sec, 2);
      else return '0:00'; 
    }
  }

  return (
    <TopLevelWrapper>
      <TotalSectionTitleWrapper>
        <SectionTitleWrapper>
          <SectionColorBox isScale={sectionData.isScale}>
            <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>{decideSectionText()}</Text>
          </SectionColorBox>
          <SectionTitleBox isScale={sectionData.isScale}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{title}</Text>
          </SectionTitleBox>
        </SectionTitleWrapper>

        {
          sectionData.tag === 'TABATA' &&
          <TABATASectionWrapper>
            <TABATARoundBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{sectionData.round + 'R'}</Text>
            </TABATARoundBox>
            <TABATAOnOffBox backgroundColor='#FF8B48'>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{'ON ' + decideTABATAOnOff('ON')}</Text>
            </TABATAOnOffBox>
            <TABATAOnOffBox backgroundColor='#6DD49E'>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{'REST ' + decideTABATAOnOff('OFF')}</Text>
            </TABATAOnOffBox>
          </TABATASectionWrapper>
        }
      </TotalSectionTitleWrapper>
      {
        (sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') &&
        <VideoWrapper height={254}>
            <iframe
              width="100%"
              height="100%"
              src={(sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') ? `https://www.youtube.com/embed/` + sectionData.youtubeLink.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '') : ''}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube" />
        </VideoWrapper>
      }
      {
        sectionData.youtubeLinks.map((data, idx) => (
          <VideoWrapper height={254}>
            <iframe
              width="100%"
              height="100%"
              src={(data.youtubeLink !== undefined && data.youtubeLink !== '') ? `https://www.youtube.com/embed/` + data.youtubeLink.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '') : ''}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube" />
          </VideoWrapper>
        ))
      }
      <BottomTotalWrapper>
        {
          (sectionData.tag !== 'SINGLE') &&
          <NoteSectionWrapper>
            <NoteSection>
              <ProgramBoxWrapper>
                <ProgramBox backgroundColor='#969696'>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>Program</Text>
                </ProgramBox>
                <PlusBox
                  backgroundColor='#969696'
                  onClick={() => setIsClickProgramMore(!isClickProgramMore)}>
                  <PlusWrapper>
                    <PlusWidth width={10} height={2} radius={3} />
                    {!isClickProgramMore && <PlusWidth width={2} height={10} radius={3} />}
                  </PlusWrapper>
                </PlusBox>
              </ProgramBoxWrapper>
              <NoteBox isClickedMore={isClickProgramMore}>
                <TextWrapper>
                  <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323'>{sectionData.mainProgram}</PreText>
                </TextWrapper>
              </NoteBox>
              {
                (sectionData.tag === 'ForTime' && sectionData.isUseTimeLimit) &&
                <TimeLimitViewWrapper>
                  <TimeLimitView>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{decideTimeCap()}</Text>
                  </TimeLimitView>
                </TimeLimitViewWrapper>
              }
            </NoteSection>
          </NoteSectionWrapper>
        }
        {
          (sectionData.tag === 'SINGLE') &&
          sectionData.mainExerciseList.map((data, index) => (
            <ResultExerciseBox
              key={index}
              index={index}
              division='Main'
              tag={sectionData.tag}
              reps={dataReps}
              exerciseData={data} />
          ))
        }
        {
          sectionData.mainNote !== '' &&
          <NoteSectionWrapper>
            <NoteSection>
              <ProgramBoxWrapper>
                <ProgramBox backgroundColor='#FF8B48'>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>Notes</Text>
                </ProgramBox>
                <PlusBox
                  backgroundColor='#FF8B48'
                  onClick={() => setIsClickNoteMore(!isClickNoteMore)}>
                  <PlusWrapper>
                    <PlusWidth width={10} height={2} radius={3} />
                    {!isClickNoteMore && <PlusWidth width={2} height={10} radius={3} />}
                  </PlusWrapper>
                </PlusBox>
              </ProgramBoxWrapper>
              <NoteBox isClickedMore={isClickNoteMore}>
                <TextWrapper>
                  <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323'>{sectionData.mainNote}</PreText>
                </TextWrapper>
              </NoteBox>
            </NoteSection>
          </NoteSectionWrapper>
        }
        <DivisionLine style={{ marginTop: 23, marginBottom: 12 }} />
        <ExerciseResultBox
          data={sectionData}
          userName={userName} />
      </BottomTotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 16px;
  margin: 8px 0px 16px 0px;

  width: 100%;
  height: ${props => props.height}px;

  background-color: #E0E0E0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  white-space: pre-wrap;
  word-break: break-all;

  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;
  max-height: 1px;

  background-color: #E0E0E0;
`;

const TotalSectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 10px 0px 10px 0px;

  width: 100%;

  background-color: #FFFFFF;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin: 10px 0px 10px 0px;

  width: 90.33%;
`;

const SectionColorBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isScale ? `43px` : `32px`};
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isScale ? '#4D92EF' : '#6DD49E'};
`;

const SectionTitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 12px 0px 12px;

  width: ${props => props.isScale ? `84%` : `89.3%`};
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const BottomTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  background-color: #F9F9F9;
`;

const NoteSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 20px;

  width: 104%;
`;

const NoteSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 90.33%;
`;

const TimeLimitViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TimeLimitView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 94%;
  height: 24px;

  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  background-color: #FF3131;
`;

const ProgramBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ProgramBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 5px;

  height: 20px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.backgroundColor};
`;

const PlusBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50px;
  height: 20px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const PlusWidth = styled.div`
  position: absolute;
  
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: #FFFFFF;
`;


const NoteBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 8px 0px 16px 0px;

  width: 100%;
  // height: ${props => props.isClickedMore ? 'auto' : '63px'};
  height: ${props => props.isClickedMore ? 'auto' : '62px'};

  // overflow: hidden;

  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  white-space: pre-wrap;
  word-break: break-all;

  justify-content: flex-start;
  align-items: flex-start;

  // margin-top: 6px;
  // margin-bottom: 13px;

  // width: 89.23%;
  width: 90.33%;
  height: 100%;

  // overflow: auto;
  overflow: hidden;

  // background-color: red;
`;

const TABATASectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: -5px;
  margin-bottom: 10px;

  width: 90.33%;
`;

const TABATARoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 18%;
  height: 31px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TABATAOnOffBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 40%;
  height: 31px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;
