import React from "react";
import styled from "styled-components";

export default function RoundInput(props) {
  const { value, setValue, unit, placeholder } = props;
  
  
	function handleClick(e) {
	}

	function handleChange(e) {
    // let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 선택하는 정규식
    // let result = e.target.value.replace(regex, "");
    
    // if (result === '') setValue(0);
		// else setValue(result);


    // var regex = /[^0-9]^\d*[.]\d*$/g;				// 숫자가 아닌 문자열을 선택하는 정규식
    let regex = /[^0-9]/g; // 숫자만 감지 
    
    let changedValue = e.target.value;
    
    if (changedValue === '') {
      setValue(0);
      return;
    }
    
    let lastValue = changedValue[changedValue.length - 1];
    console.log(changedValue);
    if (regex.test(lastValue)) {
      if (lastValue === '/'
        || lastValue === '~'
        || lastValue === '-'
        || lastValue === '.'
        || lastValue === '@') setValue(changedValue);
    }
    else {
      if (regex.test(changedValue)) setValue(changedValue);
      else setValue(parseInt(changedValue));
    }
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}
  
  return (
    <RoundInputWrapper>
      <Input
        type='text'
        placeholder={placeholder}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
      <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{unit}</Text>
    </RoundInputWrapper>
  )
}

const RoundInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;

  width: calc(100% - 22px);
  height: 34px;

  border: 1px solid #CBCBCB;
  border-radius: 30px;
`;

const Input = styled.input` 
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
  display: flex;
  flex-direction: row;

  width: 90%;

  border: 0px;
  background-color: #FFFFFF;

  color: #595959;

	outline: none;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;

		color: #CBCBCB;
	}

  &:disabled {
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;