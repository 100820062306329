import { React, useState } from 'react';
import styled from 'styled-components';

import DefaultLogoPath from '../../image/DefaultLogo.png';
// import PictureDeleteButton from '../../image/PictureDeleteButton.png';
// import PictureDeleteButton from '../../image/PictureDeleteButton.png';
import PictureDeleteButton from '../../image/PDFExitButton.png'

function LogoView(props) {
  const { data, index, onClickDeleteButton } = props;

  const {isErrorProfile, setIsErrorProfile} = useState(false);

  function decideUserProfile() {
    if (isErrorProfile || !data) return DefaultLogoPath;
    else return data;
  }

  return (
    <TopLevelWrapper>
      {/* <Logo src={data ? data : DefaultLogoPath} onError={() => setIsErrorProfile(true)}/> */}
      <Logo src={decideUserProfile()} onError={() => setIsErrorProfile(true)}/>
      <ExitButton src={PictureDeleteButton} onClick={() => onClickDeleteButton(index)}/>
    </TopLevelWrapper>
  )
}

export default LogoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;
`;

const Logo = styled.img`
  width: 99px;
  height: 99px;

  border-radius: 15px;

  // object-fit: cover;
  object-fit: contain;
`;

const ExitButton = styled.img`
  position: absolute;
  width: 32px;
  height: 32px;

  border-radius: 32px;

  object-fit: cover;

  :hover {
    cursor: pointer;
  }
`;