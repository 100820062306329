import React from "react";
import styled from "styled-components";

export default function OneOnOnePage() {

  return (
    <TopLevelWrapper>
      
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  background-color: blue;
`;