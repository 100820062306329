import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import NewTitleInput from '../../../Component/NoticeComponent/NewTitleInput';
import NewTextArea from '../../../Component/NoticeComponent/NewTextArea';

import NoticePictureIcon from '../../../image/NoticePictureIcon.png';
import WhiteExitIcon from '../../../image/WhiteExitIcon.png';
import URLIcon from '../../../image/URLIcon.png';
import BlackFixingPin from '../../../image/BlackFixingPin.png';
import WhiteFixingPin from '../../../image/WhiteFixingPin.png';


const NewNoticeModifyBox = forwardRef((props, ref) => {
  const { data } = props;

  const [value, setValue] = useState('');
  const [picture, setPicture] = useState('');

  // const [imgBase64, setImgBase64] = useState();
  // const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');
  const [isClickedFixed, setIsClickedFixed] = useState(false);

  const [pictureWidth, setPictureWidth] = useState(0);
  const [pictureHeight, setPictureHeight] = useState(0);

  const [urls, setUrls] = useState([]);

  const [isNeedCheckURL, setIsNeedCheckURL] = useState(false);

  const TitleRef = useRef();
  const CanvasRef = useRef();
  const hiddenFileInput = useRef(null);
  const FileInputRef = useRef();

  useImperativeHandle(ref, () => ({
    getTitle: () => {
      return TitleRef.current.getValue();
    },
    getValue: () => {
      let fianlURL = '';

      for (let i = 0; i < urls.length; i++) {
        fianlURL += (urls[i] + ' ');
      }

      return value + '\n' + fianlURL;
    },
    getPicture: () => {
      return picture;
    },
    getIsFixed: () => {
      return isClickedFixed;
    }
  }))

  useEffect(() => {
    // setValue(data.content);
    console.log(data);
    let newContent = data.content;
    let urls = [];

    while (true) {
      let url = collectURL(newContent);

      if (url === '') break;
      else {
        newContent = newContent.replace(url, '');
        urls.push(url.replaceAll(',', ''));
      }
    }
    // let url = collectURL(responseData.data.content);

    setUrls(urls);
    setValue(newContent);

    setPicture(data.picture);
    TitleRef.current.setValue(data.title);

    let img = document.createElement('img');
    img.src = data.picture;

    img.onload = () => {
      let finalWidth = 368;
      let finalHeight = (img.height * 368) / img.width;

      setPictureWidth(368);
      setPictureHeight(finalHeight);
    }

    setIsClickedFixed(data.isFixed);
  }, [data])

  useEffect(() => {
    if (!isNeedCheckURL) return;

    let newContent = value;
    let newUrls = urls.slice();

    while (true) {
      let url = collectURL(newContent);

      if (url === '') break;
      else {
        newContent = newContent.replace(url, '');
        newUrls.push(url.replaceAll(',', ''));
      }
    }

    setUrls(newUrls);
    setValue(newContent);
    setIsNeedCheckURL(false);
  }, [value, urls, isNeedCheckURL])

  const handleClick = e => {
    hiddenFileInput.current.click();
  };

  // const handleChange = e => {

  //   var pathpoint = e.target.value.lastIndexOf('.');
  //   var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
  //   var filetype = filepoint.toLowerCase();

  //   if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

  //     // 정상적인 이미지 확장자 파일인 경우
  //     const reader = new FileReader();
  //     // setImgLocalPath(e.target.value);
  //     var tmp = e.target.value.split('\\');
  //     setImgLocalPath(tmp[tmp.length - 1]);
  //     reader.onloadend = () => {
  //       const base64 = reader.result;
  //       const naturalImage = new Image();

  //       naturalImage.src = reader.result.toString();

  //       // if (base64){
  //       //   setStoreLogo(base64.toString());

  //       // }
  //       naturalImage.onload = function (e) {

  //         let naturalWidth = e.target.naturalWidth;
  //         let naturalHeight = e.target.naturalHeight;

  //         let resizeWidth = 0;
  //         let resizeHeight = 0;

  //         if (naturalWidth < 600 || naturalHeight < 600) {
  //           resizeWidth = naturalWidth;
  //           resizeHeight = naturalHeight;
  //         }
  //         else if (naturalWidth < naturalHeight) {
  //           resizeWidth = 600;
  //           resizeHeight = (600 * naturalHeight) / naturalWidth;
  //         }
  //         else if (naturalWidth > naturalHeight) {
  //           resizeHeight = 600;
  //           resizeWidth = (600 * naturalWidth) / naturalHeight;
  //         }
  //         else {
  //           resizeWidth = 600;
  //           resizeHeight = 600;
  //         }


  //         let finalHeight = (resizeHeight * 368) / resizeWidth;
  //         // let finalHeight = (naturalHeight * 368) / naturalWidth;

  //         // console.log('width : ' + naturalWidth);
  //         // console.log('height : ' + naturalHeight);
  //         // console.log('resizeWidth : ' + resizeWidth);
  //         // console.log('resizeHeight : ' + resizeHeight);
  //         // console.log('finalHeight : ' +finalHeight);

  //         setPictureWidth(368);
  //         setPictureHeight(finalHeight);
  //         // setPictureWidth(naturalWidth);
  //         // setPictureHeight(naturalHeight);

  //         const image = new Image();
  //         const canvas = CanvasRef.current;
  //         const ctx = canvas?.getContext('2d');

  //         if (ctx) {
  //           if (naturalWidth <= 600 && naturalHeight <= 600) {
  //             canvas.width = 600;
  //             canvas.height = 600;
  //             // canvas.backgroundColor = '#FFFFFF';

  //             image.src = base64.toString();

  //             // 사진 크기 조정후 Canvas에 그리기 //
  //             image.onload = function () {
  //               ctx.clearRect(0, 0, canvas.width, canvas.height);
  //               // ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);
  //               ctx.drawImage(image, ((600 - resizeWidth) / 2), ((600 - resizeHeight) / 2), resizeWidth, resizeHeight);
  //             }

  //             // 크기 조절된 사진 데이터 정리하기 //
  //             canvas.toBlob(function (blob) {
  //               const reader = new FileReader();
  //               // Canvas에 다 그려졌을때 Event //
  //               reader.onload = function (e) {
  //                 // setStoreLogo(canvas.toDataURL());
  //                 setPicture(canvas.toDataURL())
  //               }
  //               reader.readAsDataURL(blob);
  //             })
  //           }
  //           else {
  //             setPicture(base64.toString());
  //           }
  //         }
  //         else {
  //           // throw new Error('Could not get context');
  //           alert('사용할 수 없는 파일입니다');
  //           console.log("Could not get context");
  //         }
  //       }
  //     };
  //     if (e.target.files[0]) {
  //       reader.readAsDataURL(e.target.files[0]);
  //       // setImgFile(e.target.files[0]);
  //       // setStoreLogo(e.target.files[0]);
  //     }
  //   }
  //   else {
  //     alert('.jpeg .jpg .png 파일만 가능합니다!');
  //   }
  // };

  const handleChange = e => {

    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      // setImgLocalPath(e.target.value);
      var tmp = e.target.value.split('\\');
      setImgLocalPath(tmp[tmp.length - 1]);
      reader.onloadend = () => {
        const base64 = reader.result;
        const naturalImage = new Image();

        naturalImage.src = reader.result.toString();

        // if (base64){
        //   setStoreLogo(base64.toString());

        // }
        naturalImage.onload = function (e) {

          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 0;
          let resizeHeight = 0;

          if (naturalWidth < 600) {
            resizeWidth = naturalWidth;
            resizeHeight = naturalHeight;
          }
          else {
            resizeWidth = 600;
            resizeHeight = (600 * naturalHeight) / naturalWidth;
          }

          
          let finalHeight = (resizeHeight * 368) / resizeWidth;

          setPictureWidth(368);
          setPictureHeight(finalHeight);

          const image = new Image();
          const canvas = CanvasRef.current;
          const ctx = canvas?.getContext('2d');

          if (ctx) {
            if (naturalWidth > 600) {
              canvas.width = resizeWidth;
              canvas.height = resizeHeight;
              // canvas.backgroundColor = '#FFFFFF';

              image.src = base64.toString();

              // 사진 크기 조정후 Canvas에 그리기 //
              image.onload = function () {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);
                // ctx.drawImage(image, ((600 - resizeWidth) / 2), ((600 - resizeHeight) / 2), resizeWidth, resizeHeight);
              }

              // 크기 조절된 사진 데이터 정리하기 //
              canvas.toBlob(function (blob) {
                const reader = new FileReader();
                // Canvas에 다 그려졌을때 Event //
                reader.onload = function (e) {
                  // setStoreLogo(canvas.toDataURL());
                  // console.log(canvas.toDataURL())
                  setPicture(canvas.toDataURL())
                }
                reader.readAsDataURL(blob);
              })
            }
            else {
              setPicture(base64.toString());
            }
          }
          else {
            // throw new Error('Could not get context');
            alert('사용할 수 없는 파일입니다');
            console.log("Could not get context");
          }
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
        // setImgFile(e.target.files[0]);
        // setStoreLogo(e.target.files[0]);
      }
    }
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
  };

  // 사진 삭제 버튼 클릭시 //
  function onClickDeletePicture() {
    setPicture('');
  }

  // 붙여넣기나 스페이스바, 엔터키 감지시 URL 체크 //
  function onCheckURL() {
    setTimeout(() => {
      setIsNeedCheckURL(true);
    }, 100);
  }

  // 공지사항내에 링크 추출 //
  function collectURL(text) {
    let urlRegex = /(https?:\/\/[^ ]*)/;
    let url = text.match(urlRegex) === null ? '' : text.match(urlRegex)[1];

    return url;
  }

  // 링크 클릭시 //
  function onClickURL(url) {
    window.open(url, "_blank", "noreferrer");
  }

  // URL 삭제 클릭시 //
  function onClickDeleteURL(index) {
    let newURLs = urls.slice();

    newURLs.splice(index, 1);
    setUrls(newURLs);
  }

  // 유튜브링크인지 확인 //
  function decideYoutubeLink(url) {
    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=')) {
      return false;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      return false;
    }
    else if (url.includes('https://www.youtube.com/watch?v=')
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === '')) {
      return false;
    }
    else return true;
  }

  // 유튜브링크인지 확인후 Id 만 추출 //
  function decideYoutubeLinkVideoId(url) {
    if (url.includes('https://youtu.be/')) return url.replace('https://youtu.be/', '');
    else if (url.includes('https://www.youtube.com/watch?v=')) return url.replace('https://www.youtube.com/watch?v=', '')
  }

  return (
    <TopLevelWrapper>
      <ViewWrapper>
        <ComponentWrapper>
          {/* <Title>제목</Title> */}
          <DateTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' cursor='default'>{moment(data.createdDate).format('YYYY년 M월 D일 공지')}</Text>
            <FixingButton isClicked={isClickedFixed} onClick={() => setIsClickedFixed(!isClickedFixed)}>
              <FixingWrapper opacity={isClickedFixed ? 0 : 1}>
                <FixingImage src={BlackFixingPin} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000'>상단 고정</Text>
              </FixingWrapper>
              <FixingWrapper opacity={isClickedFixed ? 1 : 0}>
                <FixingImage src={WhiteFixingPin} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>상단 고정</Text>
              </FixingWrapper>
            </FixingButton>
          </DateTitleWrapper>
          <NewTitleInput ref={TitleRef} storeLogo={data.storeLogo} placeholder="50자 이내의 제목을 입력해주세요" />
        </ComponentWrapper>
        <ComponentWrapper style={{ marginTop: `24px` }}>
          <Title>내용</Title>
          <NewTextArea value={value} setValue={setValue} checkURL={onCheckURL} />
        </ComponentWrapper>
        <URLWrapper>
          {
            urls.map((data, index) => (
              decideYoutubeLink(data) ?
                <VideoViewWrapper key={index}>
                  <iframe
                    width="100%"
                    height="100%"
                    src={(decideYoutubeLink(data)) ? `https://www.youtube.com/embed/` + decideYoutubeLinkVideoId(data) : ''}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube" />
                  <VideoDeleteButtonWrapper onClick={() => onClickDeleteURL(index)} >
                    <DeleteButton margin='10px 10px 0px 0px' width={24} height={24} radius={24}>
                      <Icon src={WhiteExitIcon} width={18} height={18} />
                    </DeleteButton>
                  </VideoDeleteButtonWrapper>
                </VideoViewWrapper>
                :
                <URLTextBox key={index}>
                  <DeleteButton onClick={() => onClickDeleteURL(index)} margin='0px 0px 0px 0px' width={15} height={15} radius={15}>
                    <Icon src={WhiteExitIcon} width={13} height={13} />
                  </DeleteButton>
                  <InnerURLTextBox onClick={() => onClickURL(data)}>
                    <Icon src={URLIcon} />
                    <TextWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' cursor='pointer'>{data}</Text>
                    </TextWrapper>
                  </InnerURLTextBox>
                </URLTextBox>
            ))
          }
        </URLWrapper>
      </ViewWrapper>
      <canvas ref={CanvasRef} style={{ display: 'none' }} />
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }} />
      {
        picture === '' ?
          <PictureLoadigButton ref={FileInputRef} onClick={handleClick}>
            <PictureIcon src={NoticePictureIcon} />
          </PictureLoadigButton>
          :
          <ImageViewWrapper width={pictureWidth} height={pictureHeight}>
            <ExitButtonWrapper>
              <ExitButton onClick={onClickDeletePicture}>
                <ExitIcon src={WhiteExitIcon} />
              </ExitButton>
            </ExitButtonWrapper>
            <ImageView src={picture} />
          </ImageViewWrapper>
      }
    </TopLevelWrapper>
  )
})

export default NewNoticeModifyBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 1020px;
  // height: 535px;

  gap: 20px;

  margin-top: 38px;
  margin-bottom: 96px;
  padding-bottom: 68px;

  // border-radius: 30px;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  diplay: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 28px 28px 28px 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const DateTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const FixingButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 88px;
  height: 28px;

  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#FF3131` : `#FBFAF9`}; 
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const FixingWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 3px;

  opacity: ${props => props.opacity};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const FixingImage = styled.img`
  width: 16px;
  height: 16px;

  object-fit: contain;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #232323;
`;

const QuillWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 16px;

  // border: none !Important;
  // width: 964px;
  // height: 320px;
`;

const PictureLoadigButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 55px;
  height: 55px;

  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const PictureIcon = styled.img`
  width: 29px;
  height: 29px;
`;

const ImageViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;

  overflow: hidden;
`;

const ImageView = styled.img`
  width: 100%;
  height: 100%;
`;

const ExitButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  width: 368px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 9px 9px 0px 0px;

  width: 24px;
  height: 24px;

  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.6);

  :hover {
    cursor: pointer;
  }
`;

const ExitIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const URLWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 15px;

  gap: 4px;

  width: 582px;

  // background-color: red;
`;

const URLTextBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 24px;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const InnerURLTextBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 39px);
  height: 24px;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 26px);
  height: 22px;

  overflow: hidden;
`;

const VideoViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  margin: 10px 0px 10px 0px;
  width: 100%;
  // height: 100%;
  // width: 547px;
  // height: 294px;
  height: 330px;
  
  overflow: hidden;

  border-radius: 8px;
  
  transition: all 0.3s ease-in-out;
`;

const VideoDeleteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  width: 100%;
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: ${props => props.margin};

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: rgba(0, 0, 0, 0.6);

  :hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;



const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;