import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import NoticeModifyBox from './NoticeModifyBox';
import NoticeCreateBox from './NoticeCreateBox';
import NoticeList from './NoticeList';

import FetchModule from '../../Share/Network/FetchModule';
import NewNoticeModifyBox from './NewNoticeModifyBox';

function NoticeModifyView(props) {
  const { noticeId } = props;

  const history = useHistory();

  const [isProcess, setIsProcess] = useState(false);
  // const [data, setData] = useState([{title: '', content: '', details: ''}]);
  const [data, setData] = useState({title: '', content: ''});
  // const [storeLogo, setStoreLogo] = useState('');
  // const [value, setValue] = useState();

  const BoxRef = useRef();

  useEffect(() => {
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.getData('notice/detail/' + noticeId + '/', 'GET');
    //     // console.log(responseData.data[0]);
    //     if (responseData.status === 200) {
    //       setData(responseData.data[0]);
    //       setValue(responseData.data[0].details);
    //     }
    //     else if (responseData.status === 401) history.push('/');
    //     else if (responseData.status !== 204) {}
    //   }
    // )();

    var requestData = {
      noticeId: noticeId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('notice/detail/zone', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setData(responseData.data[0]);
          // setValue(responseData.data[0].details);
        }
        else if (responseData.status === 401) history.push('/');

        // setTimeout(() => {
        //   setIsLoading(false);
        // }, 1000);
      }
    )();
  }, [])

  // useEffect(() => {
  //   console.log(data);
  // }, [data])

  function exitButtonClick() { 
    history.push('/notice/details/' + noticeId);
  }

  function addButtonClick() {
    if (isProcess) {
      alert('처리중입니다!');
      return;
    }

    var title = BoxRef.current.getTitle();
    var details = BoxRef.current.getValue();

    if (title === undefined || title === '') {
      alert('제목을 입력해주세요!');
      return;
    }

    if (details === undefined || details === '') {
      alert('내용을 입력해 주세요');
      return;
    }

    setIsProcess(true);

    var data = {
      id: noticeId,
      // storeId: window.sessionStorage.getItem('StoreId'),
      storeId: window.localStorage.getItem('StoreId'),
      title: title,
      details: details,
    }

    // console.log(data);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('notice/modify/', 'PATCH', data);
        // console.log(responseData);
        if (responseData.status === 200) history.push('/notice');
        else if (responseData.status === 401) history.push('/account');
        else if (responseData.status !== 204) {}

        setIsProcess(false);
      }
    )();
  }
// 새로운 저장 버튼 클릭시 //
function onClickNewVersionAddButtonClick() {
  if (isProcess) {
    alert('처리중입니다');
    return;
  }
  
  let title = BoxRef.current.getTitle();
  let content = BoxRef.current.getValue();
  let picture = BoxRef.current.getPicture();
  let isFixed = BoxRef.current.getIsFixed();

  if (title === undefined || title === '') {
    alert('제목을 입력해주세요!');
    return;
  }

  if (content === undefined || content === '') {
    alert('내용을 입력해 주세요');
    return;
  }

  setIsProcess(true);

  let requestData = {
    noticeId: noticeId,
    storeId: window.localStorage.getItem('StoreId'),
    title: title,
    content: content,
    picture: picture,
    isFixed: isFixed
  }

  const fetchModule = new FetchModule();
  (
    async () => {
      const responseData = await fetchModule.postDataVersion2('notice/modify', 'POST', requestData);
      // console.log(responseData);
      if (responseData.status === 200) history.push('/notice');
      else if (responseData.status === 401) history.push('/');
      else if (responseData.status !== 204) {}

      setIsProcess(false);
    }
  )();
}

  return (
    <TopLevelWrapper>
      <TopBarWrapper>
        <TextWrapper>
          <Title>공지</Title>
          <Explanation>일정, 회원관리 등을 공지하고 확인할 수 있어요</Explanation>
        </TextWrapper>
        <ButtonWrapper>
          <ExitButton onClick={exitButtonClick}>취소</ExitButton>
          {/* <AddButton onClick={addButtonClick}>수정 하기</AddButton> */}
          <AddButton onClick={onClickNewVersionAddButtonClick}>수정 하기</AddButton>
        </ButtonWrapper>
      </TopBarWrapper>
      {/* <NoticeModifyBox ref={BoxRef} data={data} title={data.title} defaultVal={value}/> */}
      <NewNoticeModifyBox ref={BoxRef} data={data}/>
    </TopLevelWrapper>
  )
}

export default NoticeModifyView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // margin-left: 34px;
  margin-left: 42px;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 992px;

  // gap: 674px;
  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;