import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOffPath from '../../image/SelectOff.png';
import CheckImage from '../../image/CheckImage.png';

export default function NewCoachSelectBox(props) {
  const { value, setValue, teacherId, setTeacherId } = props;
  const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
  const [options, setOptions] = useState([]);

  const TopLevelRef = useRef();
	const SelectRef = useRef();

	// useImperativeHandle(ref, () => ({
	// 	getValue: (val) => {
	// 		return defaultVal;
	// 	},
	// 	setValue: (val) => {
	// 		setTeacherId(val);
	// 	},
	// 	setIsClicked: () => {	
	// 		if (isClicked) {
  //       SelectRef.current.style.border = '0px';
  //       SelectRef.current.style.height = `0px`;
  //       SelectRef.current.style.overflow = 'hidden';
	// 			setIsClicked(false);
	// 		}
	// 	}
	// }))

  useEffect(() => {
    setOptions([]);
    (
      async () => {
        const fetchModule = new FetchModule();
        const data = await fetchModule.getData('coach/select/', 'GET');
				
        if (data.status === 200) setOptions(data.data);
        else if (data.status === 401) history.push('/');
        else {};
      }
    )();
  }, [history])

	useEffect(() => {
		if (options.length === 0 || teacherId === -1) return;
    
    var teacherIdList = teacherId.split(',');

    var newValue = [];

    for (var i = 0; i < teacherIdList.length; i++) {
      newValue.push(options[options.findIndex(j => j.id === parseInt(teacherIdList[i]))])
    }

    setValue(newValue);
	}, [options, teacherId])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
      SelectRef.current.style.border = '0px';
      SelectRef.current.style.height = `0px`;
      SelectRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
  }

	function onClick() {
		if (isClicked) {
			SelectRef.current.style.border = '0px';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = (32 * (options.length + 1 )) > 130 ? `130px` : ((32 * (options.length + 1)) + 'px');
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

  // 코치 클릭시
	function optionalClick(index) {
    // 입력시 중복확인 => 존재하면 삭제, 존재하지 않으면 추가
    var newDefaultVal = value.slice();

    if (newDefaultVal.includes(options[index])) {
      var deletedIndex = 0;

      newDefaultVal.filter(function(item, idx) {
        // 두개가 같으면
        if (options[index] === item) {
          deletedIndex = idx;
          return;
        }
      })

      newDefaultVal.splice(deletedIndex, 1);
      setValue(newDefaultVal);
    }
    else {
      newDefaultVal.push(options[index]);
      setValue(newDefaultVal);
      setTeacherId(-1);
    }
	}

	return (
    <TopLevelWrapper ref={TopLevelRef}>
      <DefaultView 
        isClicked={isClicked}
        onClick={onClick}>
        <ColorBoxTotalWrapper>
          <ColorBoxWrapper>
            {
              value.map((data, index) => (
                <ColorMapper key={index}>
                  { index < 3 && <ColorBox>{index === 0 || index === 1 ? data.name : `+` + (value.length - 2)}</ColorBox> }
                </ColorMapper>
              ))
            }
          </ColorBoxWrapper>
        </ColorBoxTotalWrapper>
        <ArrowImage src={SelectOffPath} isClicked={isClicked}/>
      </DefaultView>
      <SelectBox
        ref={SelectRef}
        isClicked={isClicked}>
        {
          options.map((data, index) =>
            <OptionWrapper key={index} isClicked={value.includes(data)} onClick={() => optionalClick(index)}>
              <Option>{data.name}</Option>
              { value.includes(data) && <Check src={CheckImage}/> }
            </OptionWrapper>
          )
        }
      </SelectBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	
	// width: 344px;
  width: 100%;
	height: 32px;
`;

const DefaultView = styled.div`
  display: flex;
	flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0 8px 0 8px;

	// width: 328px;
  width: calc(100% - 18px);
	height: 30px;
  
  z-index: 3;

	border: 1px solid #CBCBCB;
	border-radius: 8px;

	background-color: rgba(255, 255, 255, 1);
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  width: 24px;
  height: 24px;

  transition: all 0.3s ease-in-out;

  transform: ${props => props.isClicked ? `rotateZ(-180deg)` : `rotateZ(0deg)`};

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 12px;
  height: 9px;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

  margin-top: 40px;

	// width: 328px;
  // width: calc(45.45% - 18px);
  width: calc(45.45% - 2px);
	height: 0px;

  // padding: 0 8px 0 8px;

  z-index: 2;

  border: 0px;
	border-radius: 8px;
	overflow: hidden;

	background-color: rgba(255, 255, 255, 1);

  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		// width: 9px;
    width: 0px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const ColorBoxTotalWrapper = styled.div`
	display: flex;
	flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

	width: calc(100% - 16px);

  padding: 0 8px 0 8px;

	:hover {
		cursor: pointer;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

	width: calc(100% - 16px);

  padding: 0 8px 0 8px;

  color: ${props => props.isClicked ? `#FF8B48` : `#555555`};

	:hover {
		cursor: pointer;
    color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};
    background-color: ${props => props.isClicked ? `#FFFFFF` : `#FF8B48`};
	}

  transition: all 0.3s ease-in-out;
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  margin: 6px 0 6px 0;
  padding: 0 0 0 0;
`;

const ColorBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ColorMapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ColorBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  padding: 0 8px 0 8px;

  height: 24px;

  border-radius: 6px;
  background-color: #FF8B48;

	font-family: NotoSansKR-Regular;
	font-size: 14px;

  color: #FFFFFF;

  overflow: hidden;
`;