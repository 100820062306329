import { React, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';

// import SettingTimeSelectBox from './SettingTimeSelectBox';

import DownImage from '../../image/down.png';
import UpImage from '../../image/up.png';

export default function MaxReserveQueue(props) {
  const { value, setValue } = props;

	function handleChange(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    let result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    // console.log(result);	
		setValue(result === '' ? 0 : parseInt(result));
	}

	function handleFocus(e) {

	}


  return (
    <TopLevelWrapper>
      <TopWrapper>
        <LeftWrapper>
          <Input
            placeholder={0}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus} />
        </LeftWrapper>
        <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777'>명</Text>
        </TopWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin-top: 8px;

  transition: all 0.3s ease-in-out;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 10px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;

  align-items: center;

  // width: 69px;
  width: 53px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  padding: 0 8px 0 8px;
  // margin: 0 5px 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #CBCBCB;
  color: #000000;

  ::placeholder {
    color: #CBCBCB;
  }
`;

const Text = styled.p`
  font-fmaily: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};
  // font-family: NotoSansKR-Regular;
  // font-size: 12px;

  // color: #CBCBCB;

  // margin: 0 0 0 79px;
  margin: 0 0 0 0;
`;