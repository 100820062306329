import { React, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import DayCountInput from '../../../../../Component/MemberComponent/MemberDetail/DayCountInput.js';
import DayApplyInput from '../../../../../Component/MemberComponent/MemberDetail/DayApplyInput.js';
import MemberShipSearchInput from '../../../../../Component/MemberComponent/MemberDetail/MemberSearchInput.js';

import FetchModule from '../../../../Share/Network/FetchModule.js';
import DayReasonInput from '../../../../../Component/MemberComponent/MemberDetail/DayReasonInput.js';
import CountInput from '../../../../../Component/MemberComponent/MemberDetail/CountInput.js';
import CalenderInput from '../../../../../Component/MemberComponent/CalenderInput.js';

import SearchExitImgPath from '../../../../../image/MemberShipInfoSearchExitButton2.png';
import moment from 'moment';
import UpwardCalenderInput from '../../../../../Component/MemberComponent/UpwardCalendarInput.js';

export default function MemberShipExtensionModal(props) {
  const { data, index, memberData, className, exitClickFn } = props;

  const history = useHistory();

  const [memberList, setMemberList] = useState([]);
  const [reason, setReason] = useState('');
  const [extendsDays, setExtendsDays] = useState(0);
  const [extendsCount, setExtendsCount] = useState(0);
  const [totalIsClicked, setTotalIsClicked] = useState(false);
  const [startDate, setStartDate] = useState(moment().format('YYYY.MM.DD'));

  const ApplyRef = useRef();
  const MemberSelectRef = useRef();

  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        // const responseData = await fetchModule.getData('member/alive/', 'GET');
        const responseData = await fetchModule.postData('member/alive/', 'POST');
        // console.log(responseData);
        if (responseData.status === 200) {
          setMemberList(responseData.data);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }, [history])

  function countInterLock(value) {
    ApplyRef.current.setValue(value);
  }

  function exitClick() {
    exitClickFn(className);
  }

  function saveClick() {
    // var days = ApplyRef.current.getValue();
    var days = extendsDays;

    if (days === 0 && extendsCount === 0) {
      alert('연장 적용일수또는 추가 수강횟수를 설정해주세요!');
      return;
    }

    if (totalIsClicked) {
      // var requestData = {
      //   days: days,
      //   plusTotalMax: extendsCount,
      //   reason: reason
      // }

      // const fetchModule = new FetchModule();
      // (
      //   async () => {
      //     const responseData = await fetchModule.postData('membership/extends/all/', 'POST', requestData);

      //     if (responseData.status === 200) {
      //       alert('회원권 연장이 적용되었습니다!');
      //       exitClickFn(className);
      //     }
      //     else if (responseData.status === 401) {
      //       alert('로그인 후 다시시도하세요');
      //       history.push('/');
      //     }
      //     else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      //   }
      // )();

      let requestData = {
        days: days,
        plusTotalMax: extendsCount,
        reason: reason,
        applyDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss')
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('membership/extends/all', 'POST', requestData);

          if (responseData.status === 200) {
            alert('회원권 연장이 적용되었습니다!');
            exitClickFn(className);
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
          else {
            alert("데이터를 저장하는데 실패하였습니다.\n잠시후 다시시도해 주세요.");
          }
        }
      )();
    }
    else {
      var memberList = MemberSelectRef.current.getDataList();
      var memberIdList = [];

      // for (var i = 0; i < memberList.length; i++) {
      //   memberIdList.push(memberList[i].memberId);
      // }
      for (var i = 1; i < memberList.length; i++) {
        memberIdList.push(memberList[i].memberId);
      }

      // if (memberIdList.length === 0) {
      //   alert('연장을 적용할 회원을 선택해주세요!');
      //   return;
      // }

      var requestData = {
        memberIdList: memberIdList,
        memberShipId: data[index].memberShipId,
        plusTotalMax: extendsCount,
        usageId: data[index].usageId,
        days: days,
        reason: reason
      }
      // console.log(requestData);
      // console.log(data[index]);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('membership/extends', 'POST', requestData);

          if (responseData.status === 200) {
            alert('회원권 연장이 적용되었습니다!');
            exitClickFn(className);
          }
          else if (responseData.status === 204) {
            alert('유효한 회원권을 소지하지 않은 회원이 존재합니다.\n연장 회원을 재 설정한 후 다시시도해주세요');
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
        }
      )();
    }
  }

  // 달력 클릭시 //
  function CalenderClick(className) {
    // console.log(className);
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <InnerWrapper>
          <ExitButtonWrapper>
            <ExitButton src={SearchExitImgPath} onClick={exitClick} />
          </ExitButtonWrapper>
          <TitleWrapper>
            <Title>회원권 연장</Title>
            <SubTitle>( {totalIsClicked ? '유효회원권 전체' : data[index].memberShipName} )</SubTitle>
          </TitleWrapper>
          <ViewWrapper>
            <DateCountWrapper>
              <DayCountInput value={extendsDays} setValue={setExtendsDays} /*changeFn={countInterLock}*/ />
              <CountInput value={extendsCount} setValue={setExtendsCount} />
              <DayReasonInput value={reason} setValue={setReason} />
            </DateCountWrapper>
            {
              totalIsClicked ?
                <MemberShipSearchInputWrapper>
                  <ApplyTotalMemberView>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' hover='default'>유효회원 전체 적용</Text>
                  </ApplyTotalMemberView>
                  <CalendarInputWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959' hover='default'>회원권 연장 적용일 (유효회원 기준일)</Text>
                    <UpwardCalenderInput disabled={false} value={startDate} setValue={setStartDate} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width={280} />
                  </CalendarInputWrapper>
                </MemberShipSearchInputWrapper>
                :
                <MemberShipSearchInputWrapper>
                  <MemberShipSearchInput
                    ref={MemberSelectRef}
                    memberList={memberList}
                    memberData={memberData}
                    totalIsClicked={totalIsClicked} />
                </MemberShipSearchInputWrapper>
            }
            {/* <MemberShipSearchInput
              ref={MemberSelectRef}
              memberList={memberList}
              memberData={memberData}
              totalIsClicked={totalIsClicked} /> */}
          </ViewWrapper>
          <ButtonWrapper>
            <CheckBoxWrapper onClick={() => setTotalIsClicked(!totalIsClicked)}>
              <CheckBox isClicked={totalIsClicked} />
              <CheckBoxText>모든 회원 일괄 적용</CheckBoxText>
            </CheckBoxWrapper>
            {/* <ExitButton onClick={exitClick}>취소</ExitButton> */}
            <SaveButton onClick={saveClick}>저장하기</SaveButton>
          </ButtonWrapper>
        </InnerWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 692px;
  height: 354px;

  border-radius: 10px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: -8px;

  width: 92%;
`;

const MemberShipSearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: 362px;
`;

const ApplyTotalMemberView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 280px;
  height: 85px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  background-color: #5BD98A;
`;

const CalendarInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  jutify-content: flex-start;
  align-items: flex-start;

  margin-top: 28px;
`;

const ExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 105%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 8px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SubTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 36px;

  gap: 36px;
`;

const DateCountWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // gap: 30px;
  gap: 23px;

  // margin: 0 0 0 28px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  gap: 16px;

  width: 100%;
`;

// const ExitButton = styled.div`
//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: center;

//   width: 72px;
//   height: 38px;

//   border: 1px solid #DFDFDF;
//   border-radius: 15px;
//   background-color: #FFFFFF;

//   font-family: NotoSansKR-Regular;
//   font-size: 14px;

//   color: #CBCBCB;

//   transition: all 0.3s ease-in-out;

//   :hover {
//     cursor: pointer;
//     border: 1px solid #777777;
//     color: #777777;
//   }
// `;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  margin: 0 0 0 341px;

  :hover {
    cursor: pointer;
  }
`;

const CheckBox = styled.div`
  width: 14px;
  height: 14px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;

  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
  }
`;

const CheckBoxText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const ExitButton = styled.img`
  width: 14px;
  height: 14px;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;