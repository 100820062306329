import { React, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import NewSearchInput from '../../../../Component/ClassManageComponent/NewSearchInput';
import FetchModule from '../../../Share/Network/FetchModule';

import DownImage from '../../../../image/down.png';
import UpImage from '../../../../image/up.png';
import WhiteArrowImage from '../../../../image/WhiteArrow.png';
import UnLockImage from '../../../../image/UnLockImage.png';
import LockImage from '../../../../image/LockImage.png';
import AttendancePlus from '../../../../image/AttendancePlus.png';
import AttendanceExit from '../../../../image/AttendanceExit.png';
import GuestProfileIcon from '../../../../image/ReserveGuestProfile.png';
import GuestNameInput from '../../../../Component/ClassManageComponent/GuestNameInput';
import GuestPhoneInput from '../../../../Component/ClassManageComponent/GuestPhoneInput';
import UserProfile from '../../../../Component/Normal/UserProfile';
import NewGuestPhoneInput from '../../../../Component/ClassManageComponent/NewGuestPhoneInput';
import NewGuestNameInput from '../../../../Component/ClassManageComponent/NewGuestNameInput';
import SelectReserveAndQueueModal from './SelectReserveAndQueueModal';
import MemberReservationInfoBox from './MemberReservationInfoBox';


import RedLockerPath from '../../../../image/RedLocker.png';
import GrayLockerPath from '../../../../image/GrayLocker.png';
import ReservedMemberInfoBox from './ReservedMemberInfoBox';
import QueuedMemberInfoBox from './QueuedMemberInfo';

export default function NewMemberReservationModal(props) {
  const {
    data,
    cancel,
    reserveDataReLoad,
    setReserveDataReLoad,
    scheduleData
  } = props;

  const [memberList, setMemberList] = useState([]);
  const [useMemberList, setUseMemberList] = useState([]);
  const [useReserveList, setUseReserveList] = useState([]);
  const [useQueueList, setUseQueueList] = useState([]);

  const [isExistQueue, setIsExistQueue] = useState(false);

  const [lessonBoxColor, setLessonBoxColor] = useState('#FF8B48');
  const [isKorea, setIsKorea] = useState(false);
  const [classTime, setIsClassTime] = useState(false);
  const [originalMaxBookedPeople, setOriginalMaxBookedPeople] = useState(0);
  const [modifyMaxBookedPeople, setModifyMaxBookedPeople] = useState('');

  const [isClickedLock, setIsClickedLock] = useState(false);

  const [isInProgress, setIsInProgress] = useState(false);
  const [rendering, setRendering] = useState(false);

  const [guestName, setGuestName] = useState('');
  const [guestPhone, setGuestPhone] = useState('');

  const [searchData, setSearchData] = useState('');
  const [saveRequestData, setSaveRequestData] = useState(undefined);

  const [maxQueue, setMaxQueue] = useState(0);
  const [state, setState] = useState(false);

  const [isSelectedReserveModal, setIsSelectedReserveModal] = useState(false);
  const [isAddGuest, setIsAddGuest] = useState(false);

  const [memberSearchViewHeight, setMemberSearchViewHeight] = useState(175);
  const [addGuestHeight, setAddGuestHeight] = useState(0);

  const [openChoiceButtonIndex, setOpenChoiceButtonIndex] = useState(undefined);

  const ClassNameRef = useRef(null);
  const MaxBookedPeopleInputRef = useRef(null);
  const history = useHistory();


  // 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // 수업 이름 색상 정하기 //
  useEffect(() => {
    setLessonBoxColor(scheduleData.color);
  }, [scheduleData])

  // 예약 조절 가능 시간 데이터 정리 //
  useEffect(() => {
    let end = moment(scheduleData.classDate.replaceAll('.', '-') + 'T' + scheduleData.classTime.split('-')[1] + ':00');
    let now = moment();

    if (end.diff(now) > 0) setState(true);
    else setState(false);
  }, [scheduleData])

  // 수업 시간뷰 정리 및 API 호출 //
  useEffect(() => {
    const korea = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

    let requestData = {
      scheduleId: scheduleData.scheduleId,
      classDate: scheduleData.classDate.replaceAll('.', '-') + 'T00:00:00'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('reserve/status', 'POST', requestData);
        // console.log(requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setIsClickedLock(responseData.data.isLock);
          setMemberList(responseData.data.availableMembers);
          setUseReserveList(responseData.data.reserveMembers);
          setUseQueueList(responseData.data.queueMembers);
          setMaxQueue(responseData.data.maxQueue);

          if (responseData.data.queueMembers.length > 0) setIsExistQueue(true);

          setIsInProgress(false);

          // 최대예약인원 변경 내역이 존재할 경우 //
          if (responseData.data.modifiedMaxBookedPeople !== -1) setOriginalMaxBookedPeople(responseData.data.modifiedMaxBookedPeople);
          // 최대 예약인원 변경 내역이 없을경우 //
          else setOriginalMaxBookedPeople(scheduleData.maxBookedPeople);


        }
      }
    )();

    if (korea.test(scheduleData.className)) setIsKorea(true);

    let classTime = scheduleData.classTime.split('-');
    let startTime = '';
    let endTime = '';

    if (classTime[0].split(':')[0] >= 12) startTime = classTime[0] + ' PM';
    else startTime = classTime[0] + ' AM'

    if (classTime[1].split(':')[0] >= 12) endTime = classTime[1] + ' PM';
    else endTime = classTime[1] + ' AM';

    setIsClassTime(startTime + ' ~ ' + endTime);
    setIsInProgress(false);
  }, [scheduleData, rendering])

  // 유효회원권 소지한 회원 명단 추출 //
  useEffect(() => {
    let findData = [];

    if (searchData !== '') {
      for (let i = 0; i < memberList.length; i++) {
        if (memberList[i].name.includes(searchData) || memberList[i].phone.includes(searchData)) findData.push(memberList[i]);
      }
    }
    else {
      findData = memberList.slice();
    }


    setGuestName('');
    setGuestPhone('');

    if (isExistQueue) setMemberSearchViewHeight(486);
    // else setMemberSearchViewHeight(175);
    else setMemberSearchViewHeight(215);
    setAddGuestHeight(0);

    setUseMemberList(findData);
  }, [memberList, data, reserveDataReLoad, searchData, isExistQueue])

  // 수업 이름 박스 옆 수업 데이터 텍스트 최대 크기 결정 //
  function decideClassInfoTextWidth() {
    let boxWidth = ClassNameRef.current?.offsetWidth;

    return 370 - boxWidth - 6;
  }

  // 수업 정원 창 클릭시 //
  function onClickModifyMaxBookedPeople() {
    MaxBookedPeopleInputRef.current?.focus();
  }

  // 회원 예약 버튼 클릭시 //
  function onClickMemberReserveButton(index) {
    if (isClickedLock) {
      alert('예약이 잠겨있습니다.');
      return;
    }
    else if (isInProgress) {
      alert('처리중입니다');
      return;
    }

    setIsInProgress(true);
    
    let requestData = {
      scheduleId: scheduleData.scheduleId,
      usageId: useMemberList[index].usageId,
      linkCoachId: useMemberList[index].userId,
      classDate: scheduleData.classDate.replaceAll('.', '-') + "T00:00:00",
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const reserveResponseData = await fetchModule.postDataVersion2('reserve/create/manager', 'POST', requestData);
    
        if (reserveResponseData.status === 200) {
          setReserveDataReLoad(!reserveDataReLoad);
          setRendering(!rendering);
    
          setSearchData('');
          setIsInProgress(false);
        }
        else if (reserveResponseData.status === 201) {
          alert('알맞은 회원권을 소지한 회원이 아닙니다');
          setIsInProgress(false);
        }
        else if (reserveResponseData.status === 202) {
          alert('이미 예약한 회원입니다');
          setIsInProgress(false);
        }
      }
    )();

    // let requestData = {
    //   scheduleId: scheduleData.scheduleId,
    //   classDate: scheduleData.classDate.replaceAll('.', '-') + "T00:00:00",
    // }

    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.postDataVersion2('reserve/available/check', 'POST', requestData);

    //     // 예약 가능인경우 예약 진행 //
    //     if (responseData.status === 200) {
    //       let requestData = {
    //         scheduleId: scheduleData.scheduleId,
    //         usageId: useMemberList[index].usageId,
    //         linkCoachId: useMemberList[index].userId,
    //         classDate: scheduleData.classDate.replaceAll('.', '-') + "T00:00:00",
    //       }

    //       const reserveResponseData = await fetchModule.postDataVersion2('reserve/create/manager', 'POST', requestData);
          
    //       if (reserveResponseData.status === 200) {
    //         setReserveDataReLoad(!reserveDataReLoad);
    //         setRendering(!rendering);

    //         setSearchData('');
    //         setIsInProgress(false);
    //       }
    //       else if (reserveResponseData.status === 201) {
    //         alert('알맞은 회원권을 소지한 회원이 아닙니다');
    //         setIsInProgress(false);
    //       }
    //       else if (reserveResponseData.status === 202) {
    //         alert('이미 예약한 회원입니다');
    //         setIsInProgress(false);
    //       }
    //     }
    //     // 예약인원이 다 찬 경우 //
    //     else if (responseData.status === 201) {
    //       let requestData = {
    //         scheduleId: scheduleData.scheduleId,
    //         usageId: useMemberList[index].usageId,
    //         linkCoachId: useMemberList[index].userId,
    //         classDate: scheduleData.classDate.replaceAll('.', '-') + "T00:00:00",
    //       }
          
    //       setSaveRequestData(requestData);
    //       setIsInProgress(false);
    //       setIsSelectedReserveModal(true);
    //     }
    //   }
    // )();
  }

  // 회원 예약 취소 버튼 클릭시 //
  function onClickMemberExceptButton(index) {
    if (isInProgress) {
      alert('처리중입니다');
      return;
    }

    setIsInProgress(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/delete/manage/' + useReserveList[index].reserveId, 'POST');

        if (responseData.status === 200) {
          setReserveDataReLoad(!reserveDataReLoad);
          setRendering(!rendering);
          setIsInProgress(false);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 수업 정원 변경 //
  function onChangeModifyMaxBookedPeople(e) {
    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) {
      setModifyMaxBookedPeople(e.target.value);
    }
    else if (e.target.value === '') setModifyMaxBookedPeople('');
  }

  // 수업 정원 증가 클릭 //
  function upButtonClick() {
    if (modifyMaxBookedPeople === '') {
      setModifyMaxBookedPeople(originalMaxBookedPeople + 1);
    }
    else setModifyMaxBookedPeople(modifyMaxBookedPeople + 1);
  }

  // 수업 정원 감소 클릭 //
  function downButtonClick() {
    if (modifyMaxBookedPeople > 0) {
      setModifyMaxBookedPeople(modifyMaxBookedPeople - 1);
    }
    else if (modifyMaxBookedPeople === 0) setModifyMaxBookedPeople('');
    else if (modifyMaxBookedPeople === '') {
      if (originalMaxBookedPeople > 0) setModifyMaxBookedPeople(originalMaxBookedPeople - 1);
    }
  }

  // 예약 잠금 버튼 클릭시 //
  function onClickReserveLockButton() {
    if (isInProgress) {
      alert('처리중입니다');
      return;
    }

    setIsInProgress(true);

    let requestData = {
      scheduleId: scheduleData.scheduleId,
      classDate: scheduleData.classDate.replaceAll('.', '-') + "T00:00:00",
    }

    // 예약 잠금 풀기 //
    if (isClickedLock) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('reserve/unlock', 'POST', requestData);

          if (responseData.status === 200) {
            setReserveDataReLoad(!reserveDataReLoad);
            setRendering(!rendering);
            setIsClickedLock(false);
            setIsInProgress(false);
          }
          else if (responseData.status === 201) {
            alert('잘못된 접근입니다.');
            setIsInProgress(false);
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    // 예약 잠금하기 //
    else {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('reserve/lock', 'POST', requestData);

          if (responseData.status === 200) {
            setReserveDataReLoad(!reserveDataReLoad);
            setRendering(!rendering);
            setIsClickedLock(true);
            setIsInProgress(false);
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
  }

  // 닫기 버튼 클릭시 //
  function onClickExit() {
    // 수업 정원이 기본형태가 아닌경우 //
    if (modifyMaxBookedPeople !== '') {
      // 원본 수업 정원과 같지 않을경우 실행 //
      if (originalMaxBookedPeople !== modifyMaxBookedPeople) {
        // console.log("??");
        let requestData = {
          scheduleId: scheduleData.scheduleId,
          classDate: scheduleData.classDate.replaceAll('.', '-') + "T00:00:00",
          modifyMaxBookedPeople: modifyMaxBookedPeople
        }

        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('max_booked/save', 'POST', requestData);

            if (responseData.status === 200) {
              setReserveDataReLoad(!reserveDataReLoad);
              // setRendering(!rendering);
              // setIsClickedLock(true);
              // setIsInProgress(false);
            }
            else if (responseData.status === 401) history.push('/');
          }
        )();
      }
    }
    cancel();
  }

  // 회원 이름 또는 사진 클릭시 //
  function onClickMemberProfileOrText(data) {
    if (data.memberId === 0 && data.userId.includes('Guest-')) {
      alert('일일 사용자는 조회할 수 없습니다.');
      return;
    }
    else history.push('/member/valid/detail/' + data.memberId);
  }

  // 회원 전화번호 표시 //
  function onViewUserPhone(phone) {
    if (phone === undefined || phone === '') return '전화번호 미등록';
    else return phone.slice(0, 3) + '-' + phone.slice(3, 7) + '-' + phone.slice(7, 11);
  }

  // 출석 버튼 텍스트 결정 //
  function onCalcButtonText(data) {
    if (data.status === 1 || data.status === 2) return '출석 인정';
    else return '출석 확인';
  }

  // 출석 버튼 클릭시 //
  function onClickAttendanceButton(data) {
    if (data.status === 0 || data.status === 3) {
      let requestData = {
        reserveId: data.reserveId,
        status: 1
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('reserve/attendance/check/in_modal', 'POST', requestData);

          if (responseData.status === 200) {
            alert('출석 처리가 완료되었습니다.');
            setRendering(!rendering);
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else {
      let requestData = {
        reserveId: data.reserveId,
        status: 3
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('reserve/attendance/check/in_modal', 'POST', requestData);

          if (responseData.status === 200) {
            alert('출석 취소가 완료되었습니다.');
            setRendering(!rendering);
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
  }

  // 게스트 추가 버튼 클릭시 //
  function onClickAddGuest() {
    if (isAddGuest) {
      return;
    }

    setIsAddGuest(true);

    let name = guestName;
    let phone = guestPhone;

    if (phone !== '' && phone.length < 13) {
      alert('전화번호를 올바르게 입력해주세요');
      return;
    }

    if (name === '') name = 'Guest';
    if (phone === '') phone = '010XXXXXXXX';
    else phone = phone.replaceAll('-', '');

    let requestData = {
      name: name,
      phone: phone,
      lessonId: scheduleData.lessonId,
      scheduleId: scheduleData.scheduleId,
      classTime: scheduleData.classTime,
      classDate: scheduleData.classDate.replaceAll('.', '-') + "T00:00:00",
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('reserve/create/guest', 'POST', requestData);

        if (responseData.status === 200) {
          setReserveDataReLoad(!reserveDataReLoad);
          setRendering(!rendering);

          setGuestName('');
          setGuestPhone('');
          setSearchData('');

          // setMemberSearchViewHeight(175);
          // setAddGuestHeight(0);
          if (isExistQueue) setMemberSearchViewHeight(400);
          else setMemberSearchViewHeight(215);

          setAddGuestHeight(0);

          setTimeout(() => (
            setIsAddGuest(false)
          ), 700)
        }
        else if (responseData.status === 401) history.push('/');
        else {
          alert('게스트 추가에 실패하였습니다.\n잠시 후 다시 시도해주세요');

          setTimeout(() => (
            setIsAddGuest(false)
          ), 700)
        }
      }
    )();
  }

  // 예약한 회원의 프로필 표시 //
  function onViewUserProfile(data) {
    if (data.memberId === 0 && data.userId.includes('Guest-')) return GuestProfileIcon;
    else if (data.profile !== '') return data.profile;
    else return 'https://picture.link-zone.org/ZoneLogo.png';
  }

  // 예약 대기에서 예약 추가 버튼 클릭시 //
  function onClickAddReserveOnQueue(data) {
    let requestData = {
      reserveQueueId: data.queueId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('reserve/add/zone', 'POST', requestData);

        if (responseData.status === 200) {
          setRendering(!rendering);
        }
      }
    )();
  }

  // 게스트 정보 입력 버튼 클릭시 //
  function onClickInputGuestInfo() {
    // setMemberSearchViewHeight(0);
    // setAddGuestHeight(175);
    if (isExistQueue) setAddGuestHeight(400);
    else setAddGuestHeight(215);

    setMemberSearchViewHeight(0);
  }

  // 예약열에 추가 하기 //
  function onClickAddReserveInModal() {
    if (isClickedLock) {
      alert('예약이 잠겨있습니다.');
      return;
    }
    else if (isInProgress) {
      alert('처리중입니다');
      return;
    }

    setIsInProgress(true);

    // let requestData = {
    //   scheduleId: scheduleData.scheduleId,
    //   usageId: useMemberList[index].usageId,
    //   linkCoachId: useMemberList[index].userId,
    //   classDate: scheduleData.classDate.replaceAll('.', '-') + "T00:00:00",
    // }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('reserve/create/manager', 'POST', saveRequestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          setReserveDataReLoad(!reserveDataReLoad);
          setRendering(!rendering);

          setSearchData('');
          setIsInProgress(false);
          setIsSelectedReserveModal(false);
        }
        else if (responseData.status === 201) {
          alert('알맞은 회원권을 소지한 회원이 아닙니다');
          setIsInProgress(false);
          setIsSelectedReserveModal(false);
        }
        else if (responseData.status === 202) {
          alert('이미 예약한 회원입니다');
          setIsInProgress(false);
          setIsSelectedReserveModal(false);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 대기열에 추가 하기 //
  function onClickAddQueueButton(index) {
    if (isClickedLock) {
      alert('예약이 잠겨있습니다.');
      return;
    }
    else if (isInProgress) {
      alert('처리중입니다');
      return;
    }

    setIsInProgress(true);
    
    let requestData = {
      scheduleId: scheduleData.scheduleId,
      usageId: useMemberList[index].usageId,
      linkCoachId: useMemberList[index].userId,
      classDate: scheduleData.classDate.replaceAll('.', '-') + "T00:00:00",
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('lesson_reserve_queue/save/manager', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          setReserveDataReLoad(!reserveDataReLoad);
          setRendering(!rendering);

          setSearchData('');
          setIsInProgress(false);
          setSaveRequestData(undefined);
          setIsSelectedReserveModal(false);
        }
        else if (responseData.status === 201) {
          alert('알맞은 회원권을 소지한 회원이 아닙니다');
          setIsInProgress(false);
          setSaveRequestData(undefined);
          setIsSelectedReserveModal(false);
        }
        else if (responseData.status === 202) {
          alert('이미 예약한 회원입니다');
          setIsInProgress(false);
          setSaveRequestData(undefined);
          setIsSelectedReserveModal(false);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper>
      {/* {isSelectedReserveModal && <SelectReserveAndQueueModal onClickExit={() => setIsSelectedReserveModal(false)} onClickAddReserve={onClickAddReserveInModal} onClickAddQueue={onClickAddQueueInModal} />} */}
      <ModalView isExistQueue={isExistQueue}>
        <TotalWrapper>
          <TitleWrapper>
            <TitleInnerWrapper>
              <Title>회원 예약 관리</Title>
              <Text fontFamily='Poppins-Medium' fontSize={12} color='#777777' cursor='default'>{scheduleData.className + ' | ' + classTime}</Text>
            </TitleInnerWrapper>

            <ExitButton onClick={onClickExit}>
              <ExitImage>
                <PlusWidth width={2} height={14} border={4} backgroundColor='#777777'/>
                <PlusWidth width={14} height={2} border={4} backgroundColor='#777777'/>
              </ExitImage>
            </ExitButton>
          </TitleWrapper>
          <ComponentWrapper>
            <ClassInfoBox>
              <ClassInfoComponentWrapper>
                <Text style={{ marginLeft: '10px' }} fontFamily='Poppins-Medium' fontSize={14} color='#777777' cursor='default'>수업명</Text>
                <ClassDataWrapper>
                  <LessonNameBox 
                    ref={ClassNameRef} 
                    backgroundColor={lessonBoxColor}>
                    <LessonNameInnerWrapper>
                      <Text fontFamily={isKorea ? 'NotoSansKR-Medium' : 'Poppins-Medium'} fontSize={12} color='#FFFFFF' cursor='default'>{scheduleData.className}</Text>
                    </LessonNameInnerWrapper>
                  </LessonNameBox>
                  <ClassTimeWrapper width={decideClassInfoTextWidth()}>
                    <Text style={{ whiteSpace: 'nowrap' }} fontFamily='Poppins-Medium' fontSize={12} color='#777777' cursor='default'>{scheduleData.className + ' | ' + classTime}</Text>
                  </ClassTimeWrapper>
                </ClassDataWrapper>
              </ClassInfoComponentWrapper>
              <MaxBookedPeopleTotalWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#777777' cursor='default'>수업 정원</Text>
                <MaxBookedPeopleAndLockWrapper>
                  <MaxBookedPeopleComponentWrapper>
                    <MaxBookedPeopleWrapper>
                      <MaxBookedInput
                        ref={MaxBookedPeopleInputRef}
                        isLock={isClickedLock}
                        placeholder={originalMaxBookedPeople}
                        value={modifyMaxBookedPeople}
                        onChange={onChangeModifyMaxBookedPeople} 
                        onClick={onClickModifyMaxBookedPeople}/>
                      <LockMaxBookedInput isLock={isClickedLock} >
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'>예약 불가</Text>
                      </LockMaxBookedInput>
                    </MaxBookedPeopleWrapper>
                    <MaxBookedPeopleButtonWrapper>
                      <Button onClick={upButtonClick}>
                        <ButtonImage isUp={true} src={WhiteArrowImage}/>
                      </Button>
                      <Button onClick={downButtonClick}>  
                        <ButtonImage isUp={false} src={WhiteArrowImage}/>
                      </Button>
                    </MaxBookedPeopleButtonWrapper>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#777777'>수업 정원</Text>
                  </MaxBookedPeopleComponentWrapper>
                  <LockImageWrapper>
                    <UnLock src={GrayLockerPath} isLock={isClickedLock} onClick={onClickReserveLockButton} />
                    <Lock src={RedLockerPath} isLock={isClickedLock} onClick={onClickReserveLockButton} />
                  </LockImageWrapper>
                </MaxBookedPeopleAndLockWrapper>
              </MaxBookedPeopleTotalWrapper>
            </ClassInfoBox>
          </ComponentWrapper>
          <InfoBoxWrapper>
            <InfoBox isExistQueue={isExistQueue}>
              <NewSearchInput value={searchData} setValue={setSearchData} placeholder='회원의 이름이나 전화번호를 검색해주세요' type='text' />
              <InnerInfoBox height={memberSearchViewHeight}>
                <AddGuestInfoButton onClick={onClickInputGuestInfo}>
                  <NewPlusImage width={11} height={11}>
                    <NewPlusIcon width={10} height={2} round={2} />
                    <NewPlusIcon width={2} height={10} round={2} />
                  </NewPlusImage>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' cursor='pointer'>게스트 정보 입력</Text>
                </AddGuestInfoButton>
                <ScrollBox isExistQueue={isExistQueue}>
                  {
                    useMemberList.map((memberData, index) => (
                      // <InfoWrapper key={index}>
                      //   <ImageWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
                      //     <UserProfile profile={memberData.profile} defaultProfile='https://picture.link-zone.org/ZoneLogo.png' />
                      //   </ImageWrapper>
                      //   <MemberDataWrapper>
                      //     <TextWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
                      //       <NameWrapper>
                      //         <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' cursor='pointer'>{memberData.name}</Text>
                      //       </NameWrapper>
                      //       <PhoneWrapper>
                      //         <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' cursor='pointer'>{onViewUserPhone(memberData.phone)}</Text>
                      //       </PhoneWrapper>
                      //     </TextWrapper>
                      //     <PlusButton onClick={() => onClickMemberReserveButton(index)}>
                      //       <PlusImage>
                      //         <PlusGaro />
                      //         <PlusSero />
                      //       </PlusImage>
                      //     </PlusButton>
                      //   </MemberDataWrapper>
                      // </InfoWrapper>
                      <MemberReservationInfoBox 
                        memberData={memberData}
                        index={index}
                        nowReserveLength={useReserveList.length}
                        maxReserve={modifyMaxBookedPeople === '' ? originalMaxBookedPeople : modifyMaxBookedPeople}
                        onViewUserPhone={onViewUserPhone}
                        openChoiceButtonIndex={openChoiceButtonIndex}
                        setOpenChoiceButtonIndex={setOpenChoiceButtonIndex}
                        onClickMemberProfileOrText={onClickMemberProfileOrText}
                        onClickAddQueueButton={onClickAddQueueButton}
                        onClickMemberReserveButton={onClickMemberReserveButton}/>
                    ))
                  }
                </ScrollBox>
              </InnerInfoBox>
              <InnerInfoBox height={addGuestHeight}>
                <GuestInputWrapper style={{ marginTop: '12px' }}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>게스트 전화번호</Text>
                  <NewGuestPhoneInput value={guestPhone} setValue={setGuestPhone} placeholder='010XXXXXXXX' />
                </GuestInputWrapper>
                <GuestInputWrapper style={{ marginTop: '8px' }}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>게스트 이름</Text>
                  <NewGuestNameInput value={guestName} setValue={setGuestName} placeholder='Guest' />
                </GuestInputWrapper>
                <AddGuestButton onClick={onClickAddGuest}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF' cursor='pointer'>게스트 추가 하기</Text>
                  <NewPlusImage style={{ marginTop: '2px' }} width={10} height={10}>
                    <NewPlusIcon width={10} height={2} round={2} />
                    <NewPlusIcon width={2} height={10} round={2} />
                  </NewPlusImage>
                </AddGuestButton>
              </InnerInfoBox>
            </InfoBox>
            <StatusInfoBoxWrapper>
              <StatusInfoBox>
                <MemberReserveListTitleWrapper>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#777777' cursor='default'>예약된 회원</Text>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#777777' cursor='default'>{useReserveList.length} 명 / {modifyMaxBookedPeople === '' ? originalMaxBookedPeople : modifyMaxBookedPeople} 명</Text>
                </MemberReserveListTitleWrapper>
                <StatusScrollBox isExistQueue={isExistQueue}>
                  {
                    useReserveList.map((memberData, index) => (
                      // <InfoWrapper key={index}>
                      //   <ImageWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
                      //     <UserProfile profile={onViewUserProfile(memberData)} defaultProfile='https://picture.link-zone.org/ZoneLogo.png' />
                      //   </ImageWrapper>
                      //   <MemberDataWrapper>
                      //     <TextWrapper>
                      //       <TextWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
                      //         <NameWrapper>
                      //           <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959' cursor='pointer'>{memberData.name}</Text>
                      //         </NameWrapper>
                      //         <PhoneWrapper>
                      //           {/* <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='pointer'>{memberData.phone === '' ? '전화번호 미등록' : memberData.phone.slice(0, 3) + '-' + memberData.phone.slice(3, 7) + '-' + memberData.phone.slice(7, 11)}</Text> */}
                      //           <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='pointer'>{onViewUserPhone(memberData.phone)}</Text>
                      //         </PhoneWrapper>
                      //       </TextWrapper>
                      //       <AttendanceCheckBox isComplete={(memberData.status === 1 || memberData.status === 2)} onClick={() => onClickAttendanceButton(memberData)}>
                      //         <AttendanceCheckText>{onCalcButtonText(memberData)}</AttendanceCheckText>
                      //       </AttendanceCheckBox>
                      //     </TextWrapper>
                      //     <ReserveExitButton onClick={() => onClickMemberExceptButton(index)}>
                      //       <AttendanceButton src={AttendanceExit} />
                      //     </ReserveExitButton>
                      //   </MemberDataWrapper>
                      // </InfoWrapper>
                      <ReservedMemberInfoBox
                        memberData={memberData}
                        index={index}
                        totalLength={useReserveList.length}
                        onViewUserPhone={onViewUserPhone}
                        onCalcButtonText={onCalcButtonText}
                        onClickMemberProfileOrText={onClickMemberProfileOrText}
                        onClickMemberExceptButton={onClickMemberExceptButton}
                        onClickAttendanceButton={onClickAttendanceButton}/>
                    ))
                  }
                </StatusScrollBox>
              </StatusInfoBox>
              {
                isExistQueue &&
                <StatusInfoBox style={{ marginTop: '8px' }}>
                  <MemberReserveListTitleWrapper>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#777777' cursor='default'>예약 대기열</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#777777' cursor='default'>{useQueueList.length} 명 / {maxQueue} 명</Text>
                  </MemberReserveListTitleWrapper>
                  <QueueScrollBox>
                    {
                      useQueueList.map((memberData, index) => (
                        // <InfoWrapper key={index}>
                        //   <ImageWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
                        //     <Profile src={onViewUserProfile(memberData)} />
                        //   </ImageWrapper>
                        //   <MemberDataWrapper>
                        //     <TextWrapper>
                        //       <TextWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
                        //         <NameWrapper>
                        //           <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959' cursor='pointer'>{memberData.name}</Text>
                        //         </NameWrapper>
                        //         <PhoneWrapper>
                        //           <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='pointer'>{onViewUserPhone(memberData.phone)}</Text>
                        //         </PhoneWrapper>
                        //       </TextWrapper>
                        //     </TextWrapper>
                        //     <AttendanceCheckBox isComplete={true} onClick={() => onClickAddReserveOnQueue(memberData)}>
                        //       <AttendanceCheckText>예약 추가</AttendanceCheckText>
                        //     </AttendanceCheckBox>
                        //   </MemberDataWrapper>
                        // </InfoWrapper>
                        <QueuedMemberInfoBox
                          memberData={memberData}
                          index={index}
                          onViewUserPhone={onViewUserPhone}
                          onClickAddReserveOnQueue={onClickAddReserveOnQueue}
                        />
                      ))
                    }
                  </QueueScrollBox>
                </StatusInfoBox>
              }
            </StatusInfoBoxWrapper>
          </InfoBoxWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 694px;
  width: 795px;
  height: ${props => props.isExistQueue ? '695px' : '433px'};

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 92.5%;
  width: 94.9%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const TitleInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 10px;

  // width: 98.28%;
  width: 100%;
`;

const ClassInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const MaxBookedPeopleTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // margin-top: 33px;

  // width: 41.20%;
  width: 49%;

  gap: 8px;
`;

const ClassInfoComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 49%;

  gap: 8px;

  overflow: hidden;
`;

const ClassDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: 16px;
  gap: 4px;

  width: 100%;
`;

const LessonNameBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 12px 0px 12px;

  max-width: 153px;
  height: 32px; 

  overflow: hidden;

  border-radius: 5px;
  background-color: ${props => props.backgroundColor};
`;

const LessonNameInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  max-width: 143px;
  height: 20px;

  overflow: hidden;
`;

const ClassTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: 18px;

  overflow: hidden;
`;

const MaxBookedPeopleAndLockWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 16px;

  width: 100%;
`;

const MaxBookedPeopleComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 10px;
`;

const LockImageWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const Lock = styled.img`
  position: absolute;

  opacity: ${props => props.isLock ? 1 : 0};

  // width: 23px;
  // height: 27px;
  width: 15px;
  height: 20px;

  transition: all 0.3s ease-in-out;
`;

const UnLock = styled.img`
  opacity: ${props => props.isLock ? 0 : 1};

  // width: 20px;
  // height: 27px;
  width: 15px;
  height: 20px;

  transition: all 0.3s ease-in-out;
`;

const MaxBookedPeopleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 10px;
`;

const LockMaxBookedInput = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  opacity: ${props => props.isLock ? 1 : 0};
  z-index: ${props => props.isLock ? 3 : -1};

  width: 182px;
  height: 35px;

  border-radius: 5px;
  background-color: #FF3131;

  transition: all 0.3s ease-in-out;
`;

const MaxBookedInput = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  opacity: ${props => props.isLock ? 0 : 1};

  margin: 0 0 0 0;
  padding: 0 8px 0 8px;

  width: 166px;
  height: 33px;

	outline: none;
  border: 1px solid #CBCBCB;
  border-radius: 5px;
	color: #000000;


	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;
    
		color: #CBCBCB;
	}

  transition: all 0.3s ease-in-out;
`;

const MaxBookedPeopleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 35px;
  height: 35px;

  // border: 1px solid #DFDFDF;
  border-radius: 5px;
  // background: url(${props => props.img}) no-repeat center;
  background-color: #CBCBCB;

  :hover {
    cursor: pointer;
  }
`;

const ButtonImage = styled.img`
  width: 17px;
  height: 10px;

  -webkit-transform: ${props => props.isUp ? `rotate(180deg)` : `rotate(0deg)`};
`;

const StatusInfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // margin-top: 22px;

  // width: 47.02%;
  width: 49%;
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  // margin-top: 22px;
  margin-top: 14px;

  width: 100%;
`;

const StatusInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 47.02%;
  width: 100%;
  // height: 176px;
  // height: 220px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 47.02%;
  width: 49%;
  // height: ${props => props.isExistQueue ? '406px' : '247px'};
  height: ${props => props.isExistQueue ? '521px' : '259px'};
`;

const InnerInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: ${props => props.height}px;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const MemberReserveListTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 32px;
`;

const ScrollBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 6px;

  width: 100%;
  height: ${props => props.isExistQueue ? '434px' : '184px'};

  gap: 4px;

  overflow-y: scroll;
  overflow-x: none;
  
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const StatusScrollBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  width: 100%;
  // height: 158px;
  // height: ${props => props.isExistQueue ? `158px` : `198px`};
  height: 212px;
  
  overflow-y: scroll;
  overflow-x: none;

  gap: 4px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const QueueScrollBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 8px;

  width: 100%;
  height: 212px;
  
  gap: 4px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 96%;
  height: 38px;

  gap: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border-radius: 2px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const Profile = styled.img`
  width: 100%;
  height: 100%;

  object-fit: contain;
`;

const MemberDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(100% - 32px);
  height: 38px;

  border-bottom: 1px solid #F5F5F5;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;

  :hover {
    cursor: pointer;
  }
`;

const AttendanceCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 48px;
  width: 54px;
  // height: 15px;
  height: 17px;

  border-radius: 5px;
  background-color: ${props => props.isComplete ? `#6DD49E` : `#FF8B48`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 56px;
  height: 17px;

  overflow: hidden;
`;

const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 124px;
  // width: 111px;
  // width: 106px;
  width: 105px;
  height: 17px;

  overflow: hidden;
`;

const ExitImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // transform: rotate(-45deg);
  -webkit-transform: rotate(45deg);

  :hover {
    cursor: pointer;
  }
`;

const PlusWidth = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const ExitButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: -8px;
  margin-right: -16px;

  width: 24px;
  height: 24px;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const AttendanceCheckText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 11px;

  color: #FFFFFF;

  margin: -2px 0 0 0;
  // margin-top: -1px;
  // zoom: 0.9;

  :hover {
    cursor: pointer;
  }
`;

const AddGuestInfoButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 10px;

  width: 100%;
  height: 36px;

  gap: 4px;

  border-radius: 8px;
  background-color: #FF8B48;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const GuestInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
`;

const AddGuestButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 28px;

  gap: 4px;

  border-radius: 5px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;

const NewPlusImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 2px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const NewPlusIcon = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.round}px;
  background-color: #FFFFFF;
`;