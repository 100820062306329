// import SessionStorage from './SessionStorage.js';
import LocalStorage from './LocalStorage.js';

class FetchModule {
  constructor(props){
    this.responseValue = [];
    this.baseURL = 'https://link-zone.org/';
    // this.baseURL = 'http://localhost:8080/';
    // this.baseURL = 'http://172.30.1.1:8080/';
    this.coachBaseURL = 'https://coach.link-zone.org/';
    // this.coachBaseURL = 'http://172.30.1.31:8080/';
    this.exerciseBaseURL = 'https://exercise.link-zone.org/';
    // this.exerciseBaseURL = 'http://localhost:8080/';
    // this.exerciseBaseURL = 'http://172.30.1.1:8080/';
    this.apiVersion = 'api/';//'api/';
    this.apiVersion2 = 'api2/'
    // this.token = SessionStorage.getToken();
    // this.storeId = SessionStorage.getStoreId();
    this.token = LocalStorage.getToken();
    this.refreshToken = LocalStorage.getRefreshToken();
    this.storeId = LocalStorage.getStoreId();
  }

  postConfig(setMethod, requestData) {
    return {
      method: setMethod,
      headers: {
        'Authorization': window.localStorage.getItem('Token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    }
  }

  getConfig(setMethod) {
    return {
      method: setMethod,
      headers: {
        'Authorization': window.localStorage.getItem('Token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }
  }

  // Token Refresh API //
  async refreshConfig() {
    // const token = await this.getToken();
    const accessToken = await window.localStorage.getItem('Token');
    const refreshToken = await window.localStorage.getItem('RefreshToken');

    let data = {
      accessToken: accessToken,
      refreshToken: refreshToken
    }

    return {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  }

  // Token Refresh API //
  async refreshAuthorization() {
    let config = await this.refreshConfig();
    let url = this.baseURL + this.apiVersion + 'account/refresh';

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 200) {
      await window.localStorage.setItem('Token', ('Bearer ' + json_res.token));
      if (json_res.refreshToken !== undefined) await window.localStorage.setItem('RefreshToken', ('Bearer ' + json_res.refreshToken));
    }
  }

  async deleteData(middleURL, setMethod) {
    let config = this.getConfig(setMethod);
    let url = this.baseURL + this.apiVersion + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.getConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async patchData(middleURL, setMethod, requestData) {
    let config = this.postConfig(setMethod, requestData);
    let url = this.baseURL + this.apiVersion + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }
    
    return json_res;
  }
 
  async postData(middleURL, setMethod, requestData) {
    let config = this.postConfig(setMethod, requestData);
    let url = this.baseURL + this.apiVersion + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod, requestData);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }


  async postDataOnlyLoginChange(middleURL, setMethod, requestData) {
    let config = this.postConfig(setMethod, requestData);
    let url = this.baseURL + this.apiVersion + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async getData(middleURL, setMethod) {
    let config = this.getConfig(setMethod)
    let url = this.baseURL + this.apiVersion + middleURL + this.storeId;
    
    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }
    
    return json_res;
  }

  async getIndividualData(middleURL, setMethod) {
    let config = this.getConfig(setMethod)
    let url = this.baseURL + this.apiVersion + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async postDataOfCoach(middleURL, setMethod, requestData) {
    let config = this.postConfig(setMethod, requestData);
    let url = this.coachBaseURL + this.apiVersion + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod, requestData);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async getDataOfCoach(middleURL, setMethod) {
    let config = this.getConfig(setMethod)
    let url = this.coachBaseURL + this.apiVersion + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();
    
    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  // Exercise //
  async postDataOfExercise(middleURL, setMethod, requestData) {
    let config = this.postConfig(setMethod, requestData);
    let url = this.exerciseBaseURL + this.apiVersion + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod, requestData);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }

  async getDataOfExercise(middleURL, setMethod) {
    let config = this.getConfig(setMethod)
    let url = this.exerciseBaseURL + this.apiVersion + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();
    
    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }
  // Exercise //
  
  // Zone API Version 2 //
  async getDataVersion2(middleURL, setMethod) {
    let config = this.getConfig(setMethod)
    let url = this.baseURL + this.apiVersion2 + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();
    
    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;

  }
 
  async postDataVersion2(middleURL, setMethod, requestData) {
    let config = this.postConfig(setMethod, requestData);
    let url = this.baseURL + this.apiVersion2 + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod, requestData);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }
  // Zone API Version 2 //
  
  // Coach API Version 2 //
  async getDataOfCoachVersion2(middleURL, setMethod) {
    let config = this.getConfig(setMethod)
    let url = this.coachBaseURL + this.apiVersion2 + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();
    
    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.getConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;

  }
 
  async postDataOfCoachVersion2(middleURL, setMethod, requestData) {
    let config = this.postConfig(setMethod, requestData);
    let url = this.coachBaseURL + this.apiVersion2 + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod, requestData);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }
  // Coach API Version 2 //

  // Exercise API Version 2 //
  async getDataOfExerciseVersion2(middleURL, setMethod) {
    let config = this.getConfig(setMethod)
    let url = this.exerciseBaseURL + this.apiVersion2 + middleURL;
    
    const res = await fetch(url, config);
    const json_res = await res.json();
    
    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;

  }
 
  async postDataOfExerciseVersion2(middleURL, setMethod, requestData) {
    let config = this.postConfig(setMethod, requestData);
    let url = this.exerciseBaseURL + this.apiVersion2 + middleURL;

    const res = await fetch(url, config);
    const json_res = await res.json();

    if (json_res.status === 401) {
      await this.refreshAuthorization();

      let newConfig = await this.postConfig(setMethod, requestData);

      const retryRes = await fetch(url, newConfig);
      const retryJson_res = await retryRes.json();

      return retryJson_res;
    }

    return json_res;
  }
  // Exercise API Version 2 //
}

export default FetchModule;
