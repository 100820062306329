import { forwardRef, React, useRef, useImperativeHandle, useState, useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import SearchInput from '../../../Component/MemberComponent/SearchInput.js';
import InfoPagination from '../../../Component/MemberComponent/InfoPagination.js';
import ValidColumnView from './ValidColumnView.js';
import ValidMemberInfo from './ValidMemberInfo.js';

import SortingModule from '../../Share/Normal/SortingModule.js';

import EmptyValueLogo from '../../../image/EmptyValueLogo.png';
import LoadingAnimation from '../../../Animation/MemberLoadingAnimation.json';

const ValidMemberView = forwardRef((props, ref) => {
  const { data, isLoading, memberShipDetailClick } = props;

  const [totalData, setTotalData] = useState(data);
  const [sortingData, setSortingData] = useState([]);
  const [dividedData, setDividedData] = useState([]);
  const [nowPage, setNowPage] = useState(0);
  const [value, setValue] = useState('');

  const [selectedMembership, setSelectedMembership] = useState([]);
  const [genderSort, setGenderSort] = useState('normal');
  const [birthSort, setBirthSort] = useState('normal');
  const [startSort, setStartSort] = useState('normal');
  const [endSort, setEndSort] = useState('asc');
  const [classSort, setClassSort] = useState('normal');

  const TopLevelRef = useRef();
  
  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
    setZindex: (val) => {
			TopLevelRef.current.style.zIndex = val;
    },
    setData: (val) => {
      setTotalData(val);
    }
	}))

  // 회원 자세히 보기에서 뒤로 나온경우 //
  useEffect(() => {
    setNowPage(window.localStorage.getItem('ValidMemberPage') === null ? 0 : parseInt(window.localStorage.getItem('ValidMemberPage')));
  }, [])

  // 회원 정보 소팅
  useEffect(() => {
    let newData = totalData.slice();
    
    if (selectedMembership.length !== 0) {
      let tmpNewData = [];
      
      for (let i = 0; i < newData.length; i++) {
        for (let j = 0; j < selectedMembership.length; j++) {
          if (selectedMembership[j].name === newData[i].memberShipName) {
            tmpNewData.push(newData[i]);
          }
        }
      }

      newData = tmpNewData;
    }

    if (genderSort === 'asc') newData = SortingModule.dataSort(newData, 'gender', 'asc', 'gender');
    else if (genderSort === 'desc') newData = SortingModule.dataSort(newData, 'gender', 'desc', 'gender');
    else if (birthSort === 'asc') newData = SortingModule.dataSort(newData, 'birthDay', 'asc', 'birthDay');
    else if (birthSort === 'desc') newData = SortingModule.dataSort(newData, 'birthDay', 'desc', 'birthDay');
    else if (startSort === 'asc') newData = SortingModule.dataSort(newData, 'startDate', 'asc', 'startDate');
    else if (startSort === 'desc') newData = SortingModule.dataSort(newData, 'startDate', 'desc', 'startDate');
    else if (endSort === 'asc') newData = SortingModule.dataSort(newData, 'endDate', 'asc', 'endDate');
    else if (endSort === 'desc') newData = SortingModule.dataSort(newData, 'endDate', 'desc', 'endDate');
    else if (classSort === 'asc') newData = SortingModule.dataSort(newData, 'memberShipName', 'asc', 'memberShipName');
    else if (classSort === 'desc') newData = SortingModule.dataSort(newData, 'memberShipName', 'desc', 'memberShipName');
    
    setSortingData(newData);
  }, [totalData, selectedMembership, genderSort, birthSort, startSort, endSort, classSort])

  // 검색 기능 포함 ( 한글 검색 알고리즘 최신화 ) //
  useEffect(() => {
    let nameList = [];
    let phoneList = [];

    for (let i = 0; i < sortingData.length; i++) {
      if (sortingData[i].name.toLowerCase().includes(value.toLowerCase())) nameList.push(sortingData[i]);
      else if (sortingData[i].phone.includes(value)) phoneList.push(sortingData[i]);
      // if (match(value.toLowerCase(), sortingData[i].name.toLowerCase())) nameList.push(sortingData[i]);
    }

    if (nameList.length === 0 && phoneList.length === 0) {
      for (let i = 0; i < sortingData.length; i++) {
        // if (sortingData[i].name.toLowerCase().includes(value.toLowerCase())) nameList.push(sortingData[i]);
        // else if (sortingData[i].phone.includes(value)) phoneList.push(sortingData[i]);
        // if (match(value.toLowerCase(), sortingData[i].name.toLowerCase())) nameList.push(sortingData[i]);
        if (match(value.toLowerCase(), sortingData[i].name.toLowerCase())) nameList.push(sortingData[i]);
      }
    }


    let totalList = nameList.concat(phoneList);
    let newDataList = [];
    
    // 페이지 분리 //
    if (totalList.length !== 0) {
      if (totalList.length > 20) newDataList = totalList.slice((nowPage * 20), (nowPage * 20) + 20);
      else newDataList = totalList.slice();
    }

    setDividedData(newDataList);
    // console.log(nameList)
  }, [nowPage, sortingData, value, match()])

  // 입력된 글자가 한글인지 확인 //
  function is_kr(value) {
    if (value === undefined) return false;
    
    return ((('ㄱ'.charCodeAt() <= value.charCodeAt()) && (value.charCodeAt() <= 'ㅎ'.charCodeAt()))
            || (('가'.charCodeAt() <= value.charCodeAt()) && value.charCodeAt() <= '힣'.charCodeAt()));
  }

  // 한글 글자 유니코드 추출하여 검색 조건 확인 //
  function kr_num(value) {
    return value.charCodeAt() - '가'.charCodeAt();
  }

  // 초성 중성 종성 구분하여 연산 //
  function kr_list(value) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var res = [value];

    var kr_num_s = kr_num(value);

    if (kr_num_s > 0) {
      // 초성 + 중성일 경우 //
      if (kr_num_s % 28 !== 0) {
        res.push(String.fromCharCode(((parseInt(kr_num_s / 28) * 28) + '가'.charCodeAt())));
      }
      
      res.push(consonant_list[parseInt(kr_num_s / 588)])
    }

    return res;
  }

  // 한글, 영어 구분하여 검색 처리 //
  function eq_kr(value, data) {
    if (is_kr(value) && is_kr(data)) {
      return kr_list(data).includes(value);
    }
    else return value === data;
  }

  // 받침 확인하기 //
  function eq_kr_pos(value, data, nextData) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var kr_pos = [
      [0, 0], [1, 0], [2, 1], [2, 9], [4, 2], [1, 12], [2, 18],
      [7, 3], [8, 5], [1, 0], [2, 6], [3, 7], [4, 9], [5, 16],
      [6, 17], [7, 18], [16, 6], [17, 7], [1, 9], [19, 9], [20, 10],
      [21, 11], [22, 12], [23, 14], [24, 15], [25, 16], [26, 17], [27, 18]
    ]

    if (!(is_kr(value) && is_kr(data) && is_kr(nextData))) return false;
    if (kr_num(value) < 0) return false;
    
    var s_pos = String.fromCharCode(value.charCodeAt() - kr_pos[kr_num(value) % 28][0])
    var s_pos_consonant = consonant_list[kr_pos[kr_num(value) % 28][1]]

    return (s_pos === data) && (kr_list(nextData).includes(s_pos_consonant));
  }

  // 일치하는 글자가 존재하는지 확인 //
  function match(value, data) {
    if (value === undefined || data === undefined) return false;

    var valueSize = value.length;
    var dataSize = data.length;

    if (dataSize < valueSize) return false;

    for (var i = 0; i < (dataSize - valueSize + 1); i++) {
      var res = true;

      for (var j = 0; j < valueSize; j++) {
        if (j === (valueSize - 1)) {
          if (!eq_kr(value[j], data[i + j])) {
            if (i + j + 1 < dataSize) {
              if (eq_kr_pos(value[j], data[i + j], data[i + j + 1])) {
                break;
              }
            }
            res = false;
            break;
          }
        }
        else {
          if (value[j] !== data[i + j]) {
            res = false;
            break;
          }
        }
      }

      if (res) return true;
    }
    
    return false;
  }


  // 유효회원 정보 클릭시 //
  function onClickValidMember(data) {
    window.localStorage.setItem('ValidMemberPage', nowPage);
    memberShipDetailClick(data);
  }

  // 다음 페이지 클릭시 //
  function onClickNextPage() {
    if (nowPage + 1 < Math.ceil(sortingData.length/20)) setNowPage(nowPage + 1);
  }

  // 이전 페이지 클릭시 //
  function onClickPrevPage() {
    if ((nowPage - 1) >= 0) setNowPage(nowPage - 1);  
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TopBarWrapper>
         <TextWrapper>
          <TitleWrapper>
            <Title>유효 회원</Title>
            <CountText>{'(총 ' + data.length + '명)'}</CountText>
          </TitleWrapper>
           <Explanation>등록된 모든 회원을 볼 수 있어요</Explanation>
         </TextWrapper>
         <SearchInput value={value} setValue={setValue} placeholder='회원의 이름이나 전화번호를 검색해주세요'/>
      </TopBarWrapper>
      <ValidColumnView
        selectedMembership={selectedMembership}
        setSelectedMembership={setSelectedMembership} 
        genderSort={genderSort}
        setGenderSort={setGenderSort}
        birthSort={birthSort}
        setBirthSort={setBirthSort}
        startSort={startSort}
        setStartSort={setStartSort}
        endSort={endSort}
        setEndSort={setEndSort}
        classSort={classSort}
        setClassSort={setClassSort}/>
      {
        isLoading ?
          <ViewBox>
            <LottieWrapper>
              <Lottie options={{ animationData: LoadingAnimation }} />
            </LottieWrapper>
          </ViewBox>
          :
          <ViewBox>
            {
              dividedData.length === 0 ?
                <InfoBox>
                  <EmptyLogoWrapper>
                    <EmptyLogoView src={EmptyValueLogo} />
                    <EmptyText>회원정보가 존재하지 않습니다.</EmptyText>
                  </EmptyLogoWrapper>
                </InfoBox>
                :
                <InfoBox>
                  {
                    dividedData.map((data, index) => (
                      <ValidMemberInfo key={index} data={data} onClick={() => onClickValidMember(data)} />
                    ))
                  }
                </InfoBox>
            }
          </ViewBox>
      }
      <PaginationWrapper>
        <InfoPagination 
          nowPage={nowPage} 
          // totalPage={Math.ceil(totalData.length/20)}
          totalPage={Math.ceil(sortingData.length/20)}
          nextClickFn={onClickNextPage}
          prevClickFn={onClickPrevPage}/>
      </PaginationWrapper>
    </TopLevelWrapper>
  )
})

export default ValidMemberView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
 
  // width: 100%;
  // width: 1062px;

  // margin-left: 34px;
  margin-left: 42px;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: 28px;

  // gap: 540px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
`;

const CountText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #4F4F4F;

  margin: 0 0 0 0;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 100px 0px 100px 0px;

  width: 400px;
  height: 400px;

  overflow: hidden;
  
  border-radius: 400px;
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  // margin-top: 20px;
`;

const EmptyLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 135px;
  margin-bottom: 135px;
`;

const EmptyLogoView = styled.img`
  width: 180px;
  height: 180px;

  margin-bottom: 20px;
`;

const EmptyText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 16px;

  color: #DFDFDF;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 44px;
`;