import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import lodash from 'lodash';
import DayOfWeekBox from "./DayOfWeekBox";
import WodBox from "./WodBox";
import FetchModule from "../../Model/Share/Network/FetchModule";

export default function WodCalender(props) {
  const { 
    today, 
    dates,
    clickWeekIndex,
    clickDayIndex,
    clickWodIndex,
    width,
    height,
    maxHeight,
    onClickCalenderWod,

    refreshTotalData,

    ScrollRef,

    copyWodInfo,
    setCopyWodInfo,
    lastCopyWodInfo,
    setLastCopyWodInfo,
    completeDeleteWod,
    setOpenPopUp,
    setLockPopUp
  } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [storeLogoSize, setStoreLogoSize] = useState(31);
  const [sizeDivision, setSizeDivision] = useState('Max');
  const [storeTextBoxWidth, setStoreTextBoxWidth] = useState(100); 
  const [isViewCompleteCopy, setIsViewCompleteCopy] = useState(false);
  const [videoWidth, setVideoWidth] = useState(300);
  const [videoHeight, setVideoHeight] = useState(150);

  // const ScrollRef = useRef(null);
 
  // 화면 크기 감지 //
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  // 화면 크기로 날짜 컴포넌트 크기 계산하기 //
  useEffect(() => {
    let minusPixel = 0;

    if (width === 60) minusPixel = 540;
    else if (width === 31) minusPixel = 1080;


    let calenderWidthPixel = windowWidth - minusPixel;
    let wodBoxWidthPixel = (calenderWidthPixel / 100) * 13.6;
    let videoHeightPixel = ((wodBoxWidthPixel - 15) / 100) * 51.77;

    if (wodBoxWidthPixel < 60) setSizeDivision('Min');
    else if (wodBoxWidthPixel < 90) setSizeDivision('Mid');
    else setSizeDivision('Max');

    // if (wodBoxWidthPixel < 90) setStoreLogoSize((wodBoxWidthPixel / 100) * 96);
    if (wodBoxWidthPixel < 90) setStoreLogoSize((wodBoxWidthPixel / 100) * 78);

    setStoreTextBoxWidth(((wodBoxWidthPixel / 100) * 96) - 31 - 15 - 8 - 4);
    setVideoWidth(wodBoxWidthPixel - 15);
    setVideoHeight(videoHeightPixel);
  }, [width, windowWidth])


  // 화면 감지 함수 //
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // 와드 달력 크기계산 //
  function decideTotalWidth() {
    if (width === 0) return '0px';
    else if (width === 100) return '100%';
    else if (width === 60) return 'calc(100% - 540px)';
    else return 'calc(100% - 1080px)';
  }

   // 와드 달력에서 복사 붙여넣기 실행시 //
   function onKeyDown(e) {
    const code = e.keyCode;
    let charCode = String.fromCharCode(code).toUpperCase();
    
    if (code === 27) {
      setCopyWodInfo(undefined);
      setLastCopyWodInfo(undefined);
    }
    // 복사하기 버튼 입력시 // 
    else if ((e.ctrlKey || e.metaKey) && charCode === 'C') {
      // let copyWod = JSON.parse(JSON.stringify(data.wods[clickWodIndex]));
      let copyWod = JSON.parse(JSON.stringify(dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex]));

      for (let i = 0; i < copyWod.sectionList.length; i++) {
        copyWod.sectionList[i].sectionId = undefined;

        if (copyWod.sectionList[i].scaleSections !== undefined) {
          for (let j = 0; j < copyWod.sectionList[i].scaleSections.length; j++) {
            copyWod.sectionList[i].scaleSections[j].sectionId = undefined;
            copyWod.sectionList[i].scaleSections[j].derivationSectionId = undefined;

            if (copyWod.sectionList[i].scaleSections[j].mainExerciseList != undefined) {
              for (let h = 0; h < copyWod.sectionList[i].scaleSections[j].mainExerciseList.length; h++) {
                copyWod.sectionList[i].scaleSections[j].mainExerciseList[h].exerciseId = undefined;
              }
            }
          }
        }

        if (copyWod.sectionList[i].buyinExerciseList !== undefined) {
          for (let j = 0; j < copyWod.sectionList[i].buyinExerciseList.length; j++) {
            copyWod.sectionList[i].buyinExerciseList[j].exerciseId = undefined;
          }  
        }

        if (copyWod.sectionList[i].buyinScaleOptionExerciseList !== undefined) {
          for (let j = 0; j < copyWod.sectionList[i].buyinScaleOptionExerciseList.length; j++) {
            copyWod.sectionList[i].buyinScaleOptionExerciseList[j].exerciseId = undefined;
          }
        }

        if (copyWod.sectionList[i].mainExerciseList !== undefined) {
          for (let j = 0; j < copyWod.sectionList[i].mainExerciseList.length; j++) {
            copyWod.sectionList[i].mainExerciseList[j].exerciseId = undefined;
          }  
        }

        if (copyWod.sectionList[i].mainScaleOptionExerciseList !== undefined) {
          for (let j = 0; j < copyWod.sectionList[i].mainScaleOptionExerciseList.length; j++) {
            copyWod.sectionList[i].mainScaleOptionExerciseList[j].exerciseId = undefined;
          }
        }

        if (copyWod.sectionList[i].emomExerciseList !== undefined) {
          for (let j = 0; j < copyWod.sectionList[i].emomExerciseList.length; j++) {
            copyWod.sectionList[i].emomExerciseList[j].exerciseId = undefined;
          }
        }

        if (copyWod.sectionList[i].emomScaleOptionExerciseList !== undefined) {
          for (let j = 0; j < copyWod.sectionList[i].emomScaleOptionExerciseList.length; j++) {
            copyWod.sectionList[i].emomScaleOptionExerciseList[j].exerciseId = undefined;
          }
        }

        if (copyWod.sectionList[i].buyoutExerciseList !== undefined) {
          for (let j = 0; j < copyWod.sectionList[i].buyoutExerciseList.length; j++) {
            copyWod.sectionList[i].buyoutExerciseList[j].exerciseId = undefined;
          }
        }

        if (copyWod.sectionList[i].buyoutScaleOptionExerciseList !== undefined) {
          for (let j = 0; j < copyWod.sectionList[i].buyoutScaleOptionExerciseList.length; j++) {
            copyWod.sectionList[i].buyoutScaleOptionExerciseList[j].exerciseId = undefined;
          }
        }
      }
      
      setCopyWodInfo({
        // classDate: data.wods[clickWodIndex].classDate,
        classDate: dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].classDate,
        weekIndex: clickWeekIndex,
        dayIndex: clickDayIndex,
        wodIndex: clickWodIndex,
        copyWod: copyWod
      });

      setLastCopyWodInfo(undefined);

      setIsViewCompleteCopy(true);

      setTimeout(() => (
        setIsViewCompleteCopy(false)
      ), 1500);
    }
    // 붙여넣기 버튼 입력시 //
    else if ((e.ctrlKey || e.metaKey) && charCode === 'V') {
      if (copyWodInfo === undefined) return;
      // else if (copyWodInfo.classDate === data.wods[clickWodIndex].classDate
      else if (copyWodInfo.classDate === dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].classDate
        && copyWodInfo.weekIndex === clickWeekIndex
        && copyWodInfo.dayIndex === clickDayIndex
        && copyWodInfo.wodIndex === clickWodIndex) {
        setCopyWodInfo(undefined);
        setLastCopyWodInfo(undefined);
      }
      else {
        let getPastedWod = dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex];
        let copyWod = lodash.cloneDeep(copyWodInfo.copyWod);

        copyWod.workoutId = getPastedWod.workoutId;
        copyWod.classDate = dates[clickWeekIndex][clickDayIndex].date.format('YYYY-MM-DDT00:00:00');
        // copyWod.wodVersion = getPastedWod.wodVersion;

        // Section ID 없애기 //
        for (let i = 0; i < copyWod.sectionList.length; i++) {
          copyWod.sectionList[i].sectionId = undefined;

          if (copyWod.sectionList[i].scaleSections !== undefined) {
            // ScaleSection ID Setting //
            for (let j = 0; j > copyWod.sectionList[i].scaleSections.length; j++) {
              copyWod.sectionList[i].scaleSections[j].sectionId = undefined;
              copyWod.sectionList[i].scaleSections[j].derivationSectionId = undefined;

              for (let h = 0; h < copyWod.sectionList[i].scaleSections[j].mainExerciseList.length; h++) {
                copyWod.sectionList[i].scaleSections[j].mainExerciseList[h].sectionId = undefined;
                copyWod.sectionList[i].scaleSections[j].mainExerciseList[h].exerciseId = undefined;
              }
            }
          }

          if (copyWod.sectionList[i].buyinExerciseList !== undefined) copyWod.sectionList[i].buyinExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
          if (copyWod.sectionList[i].buyinScaleOptionExerciseList !== undefined) copyWod.sectionList[i].buyinScaleOptionExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
          if (copyWod.sectionList[i].mainExerciseList !== undefined) copyWod.sectionList[i].mainExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
          if (copyWod.sectionList[i].mainScaleOptionExerciseList !== undefined) copyWod.sectionList[i].mainScaleOptionExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
          if (copyWod.sectionList[i].emomExerciseList !== undefined) copyWod.sectionList[i].emomExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
          if (copyWod.sectionList[i].emomScaleOptionExerciseList !== undefined) copyWod.sectionList[i].emomScaleOptionExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
          if (copyWod.sectionList[i].buyoutExerciseList !== undefined) copyWod.sectionList[i].buyoutExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
          if (copyWod.sectionList[i].buyoutScaleOptionExerciseList !== undefined) copyWod.sectionList[i].buyoutScaleOptionExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
        }
        
        dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex] = copyWod;
        dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved = false;

        refreshTotalData();

        setLastCopyWodInfo({
          weekIndex: clickWeekIndex,
          dayIndex: clickDayIndex,
          wodIndex: clickWodIndex
        });
        // setCopyWodInfo(undefined);
      }
    }
  }
  
  return (
    <TotalWrapper width={decideTotalWidth()} height={maxHeight}>
      <DayOfWeekBox isMinimum={sizeDivision === 'Min'}/>
      <CalenderBoxWrapper 
        ref={ScrollRef}
        tabIndex={0}
        onKeyDown={onKeyDown}>
        {
          dates.map((weekData, weekIndex) => (
            <CalenderLayerWrapper key={'Week-' + weekIndex}>
              {
                weekData.map((dayData, dayIndex) => (
                  <WodBoxWrapper key={'Day-' + dayIndex}>
                    <WodBox
                      storeLogoSize={storeLogoSize}
                      sizeDivision={sizeDivision}
                      storeTextBoxWidth={storeTextBoxWidth}
                      today={today}
                      data={dayData} 
                      weekIndex={weekIndex}
                      dayIndex={dayIndex}
                      clickWeekIndex={clickWeekIndex}
                      clickDayIndex={clickDayIndex}
                      clickWodIndex={clickWodIndex}
                      onClickWod={onClickCalenderWod}
                      
                      dates={dates}
                      refreshTotalData={refreshTotalData}

                      videoWidth={videoWidth}
                      videoHeight={videoHeight}

                      copyWodInfo={copyWodInfo}
                      setCopyWodInfo={setCopyWodInfo}
                      lastCopyWodInfo={lastCopyWodInfo}
                      setLastCopyWodInfo={setLastCopyWodInfo}
                      isViewCompleteCopy={isViewCompleteCopy}
                      setIsViewCompleteCopy={setIsViewCompleteCopy} 
          
                      setOpenPopUp={setOpenPopUp}
                      setLockPopUp={setLockPopUp}/>
                  </WodBoxWrapper>
                ))
              }
            </CalenderLayerWrapper>
          ))
        }
      </CalenderBoxWrapper>
    </TotalWrapper>
  )
}

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  overflow-x: hidden;

  width: ${props => props.width};
  height: ${props => props.height}px;

  gap: 12px;

  transition: all 0.3s ease-in-out;
`;

const CalenderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: ${props => props.width};
  // height: ${props => props.height}px;

  width: 100%;
  height: 100%;

  gap: 12px;

  overflow-x: hidden;
  overflow-y: scroll;

  outline: none;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
		border: 0px solid transparent;
		background-color: #D9D9D9;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		// background-color: #F9F9F9;
		border-radius: 200px;
    opacity: 0;
	}

  transition: all 0.3s ease-in-out;
`;

const CalenderLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: flex-start;

  padding: 0px 5px;

  width: calc(100% - 10px);
`;

const WodBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 13.6%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;