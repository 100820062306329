import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import LessonSelectBox from '../Component/LessonSelectBox';

import { makeUpWorkout } from "../Transaction/Transaction";

import WhiteGoBackImage from '../../image/WhiteGoBackButton.png';
import GrayGoBackImage from '../../image/GrayGoBackButton.png';
import WhiteExerciseUnlockPath from '../../image/UnLock.png';
import WhiteExerciselockPath from '../../image/Lock.png';
import LegacyExerciseBox from './Legacy/LegacyExerciseBox';
import ExerciseBox from './ExerciseBox';
import RecordStandardBox from './RecordStandardBox';
import AddMovementBox from './AddMovementBox';
import ExerciseViewBox from './ExerciseViewBox';
import FetchModule from '../../Model/Share/Network/FetchModule';

export default function WodDetailBox(props) {
  const {
    width,
    lessonOptions,
    selectedLesson,
    setSelectedLesson,
    maxHeight,
    dates,

    clickDate,
    clickData,
    clickWeekIndex,
    clickDayIndex,
    clickWodIndex,
    totalWorkoutData,

    nowSection,
    setNowSection,
    nowScaleSection,
    setNowScaleSection,

    movements,
    setMovements,
    recentlySearchHistory,
    setRecentlySearchHistory,

    refreshTotalData,

    onClickAddSection,
    onClickDeleteSection,
    onClickAddScaleSection,

    onClickScaleWod,
    onClickExitWodDetailBox,
    onClickExitScaleWodDetailBox, 
    onClickEditAndSave,

    onClickSaveWod,

    onClickRanking,


    modifyStartPopUp,
    setModifyStartPopUp,
    saveCompletePopUp,
    setSaveCompletePopUp,
    openPopUp,
    setOpenPopUp,
    lockPopUp,
    setLockPopUp
  } = props;

  const [originalLessonId, setOriginalLessonId] = useState(0);
  const [isNewVersionWod, setIsNewVersionWod] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isGoBackHover, setIsGoBackHover] = useState(false);

  const [wodTitle, setWodTitle] = useState('');

  const LockRef = useRef(null);


  // useEffect(() => {
  //   if (dates.length === 0 || clickWeekIndex === undefined || clickDayIndex === undefined || clickWodIndex === undefined || nowSection === undefined) {
  //     setIsReady(false);
  //     return;
  //   }

  //   let wod = dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex];
    
  //   setOriginalLessonId(wod.lessonId);
  //   setIsNewVersionWod(wod.wodVersion === 2);

  //   setWodTitle(wod.title);
  //   setIsReady(true);
  // }, [dates, clickWeekIndex, clickDayIndex, clickWodIndex, nowSection])


  useEffect(() => {
    if (dates.length === 0 || clickWeekIndex === undefined || clickDayIndex === undefined || clickWodIndex === undefined) {
      setIsReady(false);
      return;
    }

    let wod = dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex];
    // console.log(wod);
    setOriginalLessonId(wod.lessonId);
    setIsNewVersionWod(wod.wodVersion === 2);

    // setWodTitle(wod.title);
    setIsReady(true);
  }, [dates, clickWeekIndex, clickDayIndex, clickWodIndex])

  // 뒤로가기 버튼 호버링 시작 //
  function onGoBackMouseOver() {
    setIsGoBackHover(true);
  }

  // 뒤로가기 버튼 호버링 끝 //
  function onGoBackMouseLeave() {
    setIsGoBackHover(false);
  }

  // 수정중인지 아닌지 여부 판단하기 //
  function decideCompleteSave() {
    if (clickWeekIndex === undefined || clickDayIndex === undefined || clickWodIndex === undefined) return false;
    else if (dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved === undefined) return false;
    else return dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved;
  }

  // 수정중이 아닌경우 수업 선택박스 못만지게 수정 //
  function decideAvailableLessonSelectBox() {
    if (clickWeekIndex === undefined || clickDayIndex === undefined || clickWodIndex === undefined) return false;
    else return !dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved;
  }

  // 뒤로가기 클릭시 //
  function onClickExitWod() {
    setIsGoBackHover(false);
    setIsReady(false);
    onClickExitWodDetailBox();
  }

  // 잠금버튼 클릭시 //
  function onClickLockButton() {
    if (dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].workoutId === undefined) {
      dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isLock = !dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isLock;
      dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved = false;
      refreshTotalData();

      if (dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isLock) {
        setLockPopUp({
          width: 189,
          marginTop: -60,
          opacity: 1
        })

        setTimeout(() => (
          setLockPopUp({
            width: 0,
            marginTop: 35,
            opacity: 0
          })
        ), 1500)
      }
      else {
        setOpenPopUp({
          width: 214,
          marginTop: -60,
          opacity: 1
        })

        setTimeout(() => (
          setOpenPopUp({
            width: 0,
            marginTop: 35,
            opacity: 0
          })
        ), 1500)
      }
    }
    else {
      let requestData = {
        workoutId: dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].workoutId
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfExerciseVersion2('workout/replace_lock', 'POST', requestData);
          // console.log(responseData.data)
          if (responseData.status === 200) {
            dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isLock = !dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isLock;
            refreshTotalData();

            if (dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isLock) {
              setLockPopUp({
                width: 189,
                marginTop: -60,
                opacity: 1
              })

              setTimeout(() => (
                setLockPopUp({
                  width: 0,
                  marginTop: 35,
                  opacity: 0
                })
              ), 1500)
            }
            else {
              setOpenPopUp({
                width: 214,
                marginTop: -60,
                opacity: 1
              })

              setTimeout(() => (
                setOpenPopUp({
                  width: 0,
                  marginTop: 35,
                  opacity: 0
                })
              ), 1500)
            }
          }
          else if (responseData.status === 201) {
            let saveStatus = dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved;
            let newWod = [];
            
            newWod.push(JSON.parse(JSON.stringify(responseData.data)));

            let finalWod = makeUpWorkout(newWod)[0];
            dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex] = finalWod;
            dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved = saveStatus;
            refreshTotalData();

            if (dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isLock) {
              setLockPopUp({
                width: 189,
                marginTop: -60,
                opacity: 1
              })

              setTimeout(() => (
                setLockPopUp({
                  width: 0,
                  marginTop: 35,
                  opacity: 0
                })
              ), 1500)
            }
            else {
              setOpenPopUp({
                width: 214,
                marginTop: -60,
                opacity: 1
              })

              setTimeout(() => (
                setOpenPopUp({
                  width: 0,
                  marginTop: 35,
                  opacity: 0
                })
              ), 1500)
            }
          }
        }
      )();
    }
  }

  // 수정하기 및 저장하기 버튼 클릭시 //
  function onClickSaveButton(e) {
    if (LockRef.current !== e.target && !LockRef.current.contains(e.target)) {
      if (dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved) {
        dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved = false;
        // onClickExitScaleWodDetailBox();
        onClickEditAndSave();
        refreshTotalData();

        setModifyStartPopUp({
          width: 214,
          marginTop: -60,
          opacity: 1
        })

        setTimeout(() => (
          setModifyStartPopUp({
            width: 0,
            marginTop: 35,
            opacity: 0
          })
        ), 1500)
      }
      else onClickSaveWod();
    }
  }


  return (
    <TotalWodDetailBoxWrapper width={width} height={maxHeight}>
      <PopUpWrapper>
        <PopUp
          width={modifyStartPopUp.width}
          topMargin={modifyStartPopUp.marginTop}
          opacity={modifyStartPopUp.opacity}
          backgroundColor='#FF8B48'>
          <PopUpTextWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>수정이 시작되었습니다</Text>
          </PopUpTextWrapper>
        </PopUp>

        <PopUp
          width={saveCompletePopUp.width}
          topMargin={saveCompletePopUp.marginTop}
          opacity={saveCompletePopUp.opacity}
          backgroundColor='#6DD49E'>
          <PopUpTextWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>운동이 저장되었습니다</Text>
          </PopUpTextWrapper>
        </PopUp>

        <PopUp
          width={openPopUp.width}
          topMargin={openPopUp.marginTop}
          opacity={openPopUp.opacity}
          backgroundColor='#6DD49E'>
          <PopUpTextWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>운동이 공개되었습니다</Text>
          </PopUpTextWrapper>
        </PopUp>

        <PopUp
          width={lockPopUp.width}
          topMargin={lockPopUp.marginTop}
          opacity={lockPopUp.opacity}
          backgroundColor='#FF8B48'>
          <PopUpTextWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>운동이 잠겼습니다</Text>
          </PopUpTextWrapper>
        </PopUp>

      </PopUpWrapper>
      <TotalWodDetailBox>
        <WodDetailBoxWrapper>
          {
            (clickWeekIndex !== undefined && clickDayIndex !== undefined && clickWodIndex !== undefined) &&
            <TopLineWrapper>
              <TopLineLeftWrapper>
                <GoBackButton
                  onMouseOver={onGoBackMouseOver}
                  onMouseLeave={onGoBackMouseLeave}
                  // onMouseOut={onGoBackMouseLeave}
                  onClick={onClickExitWod}>
                  <GoBackButtonImg opacity={isGoBackHover ? 1 : 0} src={WhiteGoBackImage} />
                  <GoBackButtonImg opacity={isGoBackHover ? 0 : 1} src={GrayGoBackImage} />
                </GoBackButton>
                <SelectBoxWrapper enabled={decideAvailableLessonSelectBox()}>
                  <LessonSelectBox
                    options={lessonOptions}
                    originalLessonId={originalLessonId}
                    value={selectedLesson}
                    setValue={setSelectedLesson}
                    enabled={decideAvailableLessonSelectBox()} />
                </SelectBoxWrapper>
                {clickDate !== undefined && <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{clickDate.format('YYYY.MM.DD')}</Text>}
              </TopLineLeftWrapper>
              <SaveButton isCompleteSaved={decideCompleteSave()} onClick={onClickSaveButton}>
                <SaveButtonTextWrapper>
                  <LockerImageBox ref={LockRef} onClick={onClickLockButton}>
                    <LockerImg src={isReady && dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isLock ? WhiteExerciselockPath : WhiteExerciseUnlockPath} />
                  </LockerImageBox>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{decideCompleteSave() ? `수정하기` : `저장하기`}</Text>
                </SaveButtonTextWrapper>
              </SaveButton>
            </TopLineWrapper>
          }
          {
            (clickWeekIndex !== undefined && clickDayIndex !== undefined && clickWodIndex !== undefined) &&
            <WodWrapper>
              {
                // isNewVersionWod ?
                dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].wodVersion === 2 ?
                  <WodViewWrapper>
                    {
                      decideCompleteSave() ?
                        <ExerciseViewBox
                          wodTitle={wodTitle}
                          // setWodTitle={setWodTitle}

                          clickData={dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex]}

                          // onClickAddSection={onClickAddSection}
                          // onClickDeleteSection={onClickDeleteSection}
                          // onClickAddScaleSection={onClickAddScaleSection}

                          movements={movements}
                          recentlySearchHistory={recentlySearchHistory}
                          setRecentlySearchHistory={setRecentlySearchHistory}

                          refreshTotalData={refreshTotalData}

                          nowSection={nowSection}
                          setNowSection={setNowSection}
                          nowScaleSection={nowScaleSection}
                          setNowScaleSection={setNowScaleSection}

                          // onClickScaleWod={onClickScaleWod}

                          onClickRanking={onClickRanking}

                        // clickDate={clickDate}
                        // clickWeekIndex={clickWeekIndex}
                        // clickDayIndex={clickDayIndex}
                        // clickWorkoutIndex={clickWodIndex}
                        // useData={totalWorkoutData} 
                        />
                        :
                        <ExerciseBox
                          wodTitle={wodTitle}
                          setWodTitle={setWodTitle}

                          clickData={dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex]}

                          onClickAddSection={onClickAddSection}
                          onClickDeleteSection={onClickDeleteSection}
                          onClickAddScaleSection={onClickAddScaleSection}

                          movements={movements}
                          setMovements={setMovements}
                          recentlySearchHistory={recentlySearchHistory}
                          setRecentlySearchHistory={setRecentlySearchHistory}

                          refreshTotalData={refreshTotalData}

                          nowSection={nowSection}
                          setNowSection={setNowSection}
                          nowScaleSection={nowScaleSection}
                          setNowScaleSection={setNowScaleSection}

                          onClickScaleWod={onClickScaleWod}

                          clickDate={clickDate}
                          clickWeekIndex={clickWeekIndex}
                          clickDayIndex={clickDayIndex}
                          clickWorkoutIndex={clickWodIndex}
                          useData={totalWorkoutData} />
                    }
                  </WodViewWrapper>
                  :
                  <WodViewWrapper>
                    <LegacyExerciseBox
                      clickDate={clickDate}
                      clickWeekIndex={clickWeekIndex}
                      clickDayIndex={clickDayIndex}
                      clickWorkoutIndex={clickWodIndex}
                      useData={totalWorkoutData}


                      onClickRanking={onClickRanking}

                      selectedLesson={selectedLesson}
                      setSelectedLesson={setSelectedLesson}

                      isClickedModify={!dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved} />
                  </WodViewWrapper>
              }
            </WodWrapper>
          }
        </WodDetailBoxWrapper>
        {/* <WodViewWrapper> */}
        {
          ((clickWeekIndex !== undefined && clickDayIndex !== undefined && clickWodIndex !== undefined)
            && isNewVersionWod
            && dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection].tag !== 'SINGLE'
            && !decideCompleteSave()) &&
          <NewWodBottomWrapper>
            <DivisionLine />
            <RecordStandardWrapper>
              <RecordStandardBox sectionData={dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection]} />
            </RecordStandardWrapper>
            {
              dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection].tag !== 'Custom' &&
              <AddMovementBoxWrapper>
                <AddMovementBox
                  sectionData={dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection]}
                  movements={movements}
                  setMovements={setMovements}
                  recentlySearchHistory={recentlySearchHistory}
                  setRecentlySearchHistory={setRecentlySearchHistory}
                  refreshTotalData={refreshTotalData} />
              </AddMovementBoxWrapper>
            }
          </NewWodBottomWrapper>
        }
        {/* </WodViewWrapper> */}
      </TotalWodDetailBox>
    </TotalWodDetailBoxWrapper>
  )
}


const TotalWodDetailBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // margin-bottom: 30px;

  width: ${props => props.width};
  min-width: ${props => props.width};
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;

  z-index: 10;

  transition: all 0.3s ease-in-out;
`;

const PopUpWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  z-index: 1;
  transition: all 0.3s ease-in-out;
`;

const PopUp = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;

  flex-wrap: nowrap;

  margin-top: ${props => props.topMargin}px;

  width: ${props => props.width}px;
  height: 35px;

  opacity: ${props => props.opacity};

  border-radius: 30px;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const PopUpTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  flex-wrap: nowrap;

  min-width: 140px;
  height: 35px;
`;

const TotalWodDetailBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-bottom: 30px;

  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

  outline: none;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
		border: 0px solid transparent;
		background-color: #D9D9D9;
		border-radius: 2px;
	}

  &::-webkit-scrollbar-track {
		// background-color: #F9F9F9;
		border-radius: 200px;
    opacity: 0;
	}

  z-index: 2;

  transition: all 0.3s ease-in-out;
`;

const WodDetailBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  // height: 100%;
  
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);

  transition: all 0.3s ease-in-out;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;
  margin-top: 15px;

  width: calc(100% - 30px);

  z-index: 5;
`;

const TopLineLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-between;
  justify-content: flex-start;
  align-items: center;

  width: 70%;

  gap: 10px;
`;

const GoBackButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 35px;
  height: 35px;

  border-radius: 35px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
    background-color: #DFDFDF;
  }

  transition: all 0.3s ease-in-out;
`;

const GoBackButtonImg = styled.img`
  position: absolute;

  width: 9px;
  height: 15px;

  opacity: ${props => props.opacity};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: ${props => props.enabled ? `0px` : `0px 10px`};
  
  // width: 51.87%;
  width: ${props => props.enabled ? '51.87%' : '35%'};
  height: 35px;

  transition: all 0.3s ease-in-out;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 23%;
  height: 35px;

  border-radius: 30px;
  background-color: ${props => props.isCompleteSaved ? `#FF8B48` : `#6DD49E`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const SaveButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-between;
  justify-content: flex-start;
  align-items: center;

  padding-left: 4px;

  width: calc(100% - 4px);

  gap: 8px;
`;

const LockerImageBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 29px;
  height: 29px;

  border-radius: 29px;
  // background-color: #FF8B48;
  
  :hover {
    cursor: pointer;
    background-color: #FFA570;
  }

  transition: all 0.3s ease-in-out;
`;

const LockerImg = styled.img`
  width: 13px;
  height: 19px;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  z-index: 3;
`;

const WodViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const NewWodBottomWrapper = styled.div`
  // position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 35px;

  width: 100%;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 1px;

  background-color: #E5E5E5;
`;

const RecordStandardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 35px;

  width: 100%;
`;

const AddMovementBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;