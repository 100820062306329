import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './NoticeCreateBox.css';
import Lottie from 'lottie-react-web';

import TitleInput from '../../../Component/NoticeComponent/TitleInput';
import NewCommentBox from './NewCommentBox';
import NewCommentWriteBox from './NewCommentWriteBox';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';
import NewTitleInput from '../../../Component/NoticeComponent/NewTitleInput';
import URLIcon from '../../../image/URLIcon.png';
import BlackFixingPin from '../../../image/BlackFixingPin.png';
import WhiteFixingPin from '../../../image/WhiteFixingPin.png';
import DefaultLogoPath from '../../../image/DefaultLogo.png';
import UserProfile from '../../../Component/Normal/UserProfile';

const NewNoticeViewBox = forwardRef((props, ref) => {
  const { data, title, defaultVal, noticeId, storeLogo, isFixed, rendering, isLoading } = props;

  const [value, setValue] = useState(data.details);
  const [totalLength, setTotalLength] = useState(0);
  const [name, setName] = useState(undefined);
  const [commentId, setCommentId] = useState(-1);
  const [pictureWidth, setPictureWidth] = useState(0);
  const [pictureHeight, setPictureHeight] = useState(0);

  const [urls, setUrls] = useState([]);
  const [content, setContent] = useState('');

  const TitleRef = useRef();
  const CommentRef = useRef();
  const InfoRef = useRef();

  const modules = {
    toolbar: {
      container: "#toolbar",
    }
  };

  const formats = [
    "size",
    "underline",
    "italic",
    "bold",
    "align",
    "color",
    "background",
    "image"
  ];

  useImperativeHandle(ref, () => ({
    getTitle: () => {
      return TitleRef.current.getValue();
    },
    getValue: () => {
      return value;
    },
  }))

  useImperativeHandle(InfoRef, () => ({
    setName: (val) => {
      setName(val);
    },
    setCommentId: (val) => {
      setCommentId(val);
    },
  }))

  useEffect(() => {
    setValue(defaultVal);
  }, [defaultVal])

  useEffect(() => {
    console.log(moment(data.createdDate).format('YYYY-MM-DD'));
    let picture = data.picture;

    let img = document.createElement('img');
    img.src = picture;

    let newContent = data.content;
    let urls = [];

    while (true) {
      let url = collectURL(newContent);

      if (url === '') break;
      else {
        newContent = newContent.replace(url, '');
        urls.push(url.replaceAll(',', ''));
      }
    }
    // let url = collectURL(responseData.data.content);

    setUrls(urls);
    setContent(newContent);

    img.onload = () => {
      // console.log(img.height);
      // console.log(img.width);

      // let finalWidth = (screenWidth * 90.33) / 100;
      // let finalHeight = (finalWidth * height) / width;
      let finalWidth = 368;
      let finalHeight = (img.height * 368) / img.width;
      // console.log(finalHeight);
      setPictureWidth(368);
      setPictureHeight(finalHeight);
    }
  }, [data])

  useEffect(() => {
    // console.log(data);
    var count = data.commentList.length;

    for (var i = 0; i < data.commentList.length; i++) {
      count += data.commentList[i].replyList.length;
    }

    setTotalLength(count);
  }, [data])

  function handleChange(value) {
    setValue(value);
  }



  // 공지사항내에 링크 추출 //
  function collectURL(text) {
    let urlRegex = /(https?:\/\/[^ ]*)/;
    let url = text.match(urlRegex) === null ? '' : text.match(urlRegex)[1];

    return url;
  }

  // 링크 클릭시 //
  function onClickURL(url) {
    // await Linking.openURL(url);
    window.open(url, "_blank", "noreferrer");
  }

  // 유튜브링크인지 확인 //
  function decideYoutubeLink(url) {
    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=')) {
      return false;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      return false;
    }
    else if (url.includes('https://www.youtube.com/watch?v=')
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === '')) {
      return false;
    }
    else return true;
  }

  // 유튜브링크인지 확인후 Id 만 추출 //
  function decideYoutubeLinkVideoId(url) {
    if (url.includes('https://youtu.be/')) return url.replace('https://youtu.be/', '');
    else if (url.includes('https://www.youtube.com/watch?v=')) return url.replace('https://www.youtube.com/watch?v=', '')
  }

  return (
    <TopLevelWrapper>
      {
        isLoading ?
          <LoadingView>
            <LottieWrapper>
              <Lottie options={{ animationData: LoadingAnimation }} />
            </LottieWrapper>
          </LoadingView>
          :
          <ViewWrapper>
            <ContentsBox>
              <ComponentWrapper>
                {/* <Title>제목</Title> */}
                {/* <TitleInput ref={TitleRef} placeholder="50자 이내의 제목을 입력해주세요" defaultValue={title} /> */}
                {/* <NewTitleInput ref={TitleRef} placeholder="50자 이내의 제목을 입력해주세요" defaultValue={title} /> */}
                {/* <TitleBox>{title}</TitleBox> */}
                <DateTitleWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' cursor='default'>{moment(data.createdDate).format('YYYY년 M월 D일 공지')}</Text>
                  <FixingButton isClicked={data.isFixed}>
                    <FixingWrapper opacity={1}>
                      <FixingImage src={data.isFixed ? WhiteFixingPin : BlackFixingPin} />
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={data.isFixed ? '#FFFFFF' : '#000000'}>상단 고정</Text>
                    </FixingWrapper>
                  </FixingButton>
                </DateTitleWrapper>
              </ComponentWrapper>
              <ComponentWrapper style={{ marginTop: '12px' }}>
                <ProfileBoxWrapper>
                  <UserProfile profile={storeLogo} defaultProfile={DefaultLogoPath} />
                </ProfileBoxWrapper>
                <TitleBox>{title}</TitleBox>
              </ComponentWrapper>
              <ComponentWrapper style={{ marginTop: `24px` }}>
                <Title>내용</Title>
                {/* <ContentBox>{data.content}</ContentBox> */}
                <ContentBox>{content}</ContentBox>
              </ComponentWrapper>
              <URLWrapper>
                {
                  urls.map((data, index) => (
                    decideYoutubeLink(data) ?
                      <VideoViewWrapper key={index}>
                        <iframe
                          width="100%"
                          height="100%"
                          src={(decideYoutubeLink(data)) ? `https://www.youtube.com/embed/` + decideYoutubeLinkVideoId(data) : ''}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded youtube" />
                      </VideoViewWrapper>
                      :
                      <URLTextBox key={index} onClick={() => onClickURL(data)}>
                        <Image width={18} height={10} src={URLIcon} />
                        <TextWrapper>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' cursor='pointer'>{data}</Text>
                        </TextWrapper>
                      </URLTextBox>
                  ))
                }
              </URLWrapper>
              <CommentBoxWrapper>
                <CommentTitleWrapper>
                  <CommentTitle>댓글</CommentTitle>
                  <CommentCount>{totalLength}</CommentCount>
                </CommentTitleWrapper>
                {
                  data.commentList.map((commentData, index) => (
                    <NewCommentBox key={index} data={commentData} isLast={data.commentList.length === index + 1} rendering={rendering} commentRef={CommentRef} infoRef={InfoRef} />
                  ))
                }
              </CommentBoxWrapper>
              <NewCommentWriteBox noticeId={noticeId} rendering={rendering} commentRef={CommentRef} name={name} commentId={commentId} />
            </ContentsBox>
            <ImageWrapper width={pictureWidth} height={pictureHeight}>
              <Image width={pictureWidth} height={pictureHeight} src={data.picture} />
            </ImageWrapper>
          </ViewWrapper>
      }
    </TopLevelWrapper>
  )
})

export default NewNoticeViewBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px;
  min-height: 535px;

  margin-top: 38px;
  margin-bottom: 96px;

  // border-radius: 30px;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // background-color: #FFFFFF;
`;

const LoadingView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin-top: 26px;
  gap: 24px;

  width: 100%;
`;

const ContentsBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: 28px 0px 28px 0px;
  padding: 12px 0px 28px 0px;
  
  width: 627px;
  
  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const DateTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 100%;
  width: 580px;
`;

const FixingButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 88px;
  height: 28px;

  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#FF3131` : `#FBFAF9`}; 
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: default;
  }
`;

const FixingWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 3px;

  opacity: ${props => props.opacity};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: default;
  }
`;

const FixingImage = styled.img`
  width: 16px;
  height: 16px;

  object-fit: contain;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #232323;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding: 6px 14px 6px 14px;
  padding: 10px 14px 10px 57px;
  // margin-top: 8px;

  // width: 552px;
  width: 509px;
  // width: 100%;

  border: 1px solid #DFDFDF;
  // border-radius: 8px;
  border-radius: 34px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #000000;
`;

const ContentBox = styled.pre`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 8px 8px 8px;

  width: 564px;
  min-height: 277px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  white-space: pre-wrap;
  word-break: break-all;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #000000;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
  
  overflow: hidden;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const CommentBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 38px;
  margin-bottom: 24px;

  // width: 90.33%;
  width: 582px;

  // background-color: red;
`;

const CommentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;

  width: 100%;
`;

const CommentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const CommentCount = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;

const URLWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 15px;

  gap: 4px;

  width: 582px;

  // background-color: red;
`;

const URLTextBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 24px;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const VideoViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  margin: 10px 0px 10px 0px;
  width: 100%;
  // height: 100%;
  // width: 547px;
  // height: 294px;
  height: 330px;
  
  overflow: hidden;

  border-radius: 8px;
  
  transition: all 0.3s ease-in-out;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 26px);
  height: 22px;

  overflow: hidden;
`;

const ProfileBoxWrapper = styled.div`
	position: absolute;
  width: 48px;
  height: 48px;

  border-radius: 48px;
  
  overflow: hidden;

  :hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px #FF8B48);
  }

  transition: all 0.3s ease-in-out;

	background-color: #FBFAF9;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;